@import 'User.scss';
@import 'Popover.scss';
@import 'normalize.css';
@import '@blueprintjs/core/lib/css/blueprint.css';
@import '@blueprintjs/icons/lib/css/blueprint-icons.css';

body,
.cpc-navbar {
  min-width: 500px;
}

a:hover {
  text-decoration: none;
}

.pt-menu {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  padding: 10px;
  width: 218px;
  &.more-width {
    width: 265px;
  }
  .pt-menu-item {
    color: #212121;
    font-family: 'Roboto' !important;
    font-size: 13px;
    line-height: 20px;
    &::before {
      color: #195fff;
      font-family: 'icomoon', 'Icons16', sans-serif !important;
      font-size: 18px;
      line-height: 15px;
      margin-right: 16px;
    }
  }

  .pt-icon-cpc {
    &::before {
      line-height: 20px;
    }
  }
}

*:focus,
.pt-control input:focus ~ .pt-control-indicator {
  outline: 0;
  outline-offset: 0;
}

.pt-control input:checked ~ .pt-control-indicator {
  background: #195fff;
}
.pt-control:hover input:checked ~ .pt-control-indicator {
  background: #195fff;
}

.pt-dialog {
  background: #fff !important;

  .pt-dialog-header {
    padding: 13px 30px;

    h5 {
      color: #212121;
      font-size: 17px;
      line-height: 20px;
    }

    .pt-dialog-close-button {
      padding-left: 0;
      padding-right: 0;
      &::before {
        color: #c4c4c4;
        font-size: 20px;
      }
    }
  }
}

.image-preview {
  margin: 0 0 100px 0;
  margin-top: 20px;
  max-width: 80%;
  padding-bottom: 0;
  width: auto;
  .pt-dialog-header .pt-dialog-close-button::before {
    font-size: 28px;
  }
  .pt-dialog-header {
    padding: 0 15px;
  }
}

.thumb-horizontal {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: inherit;
  cursor: pointer;
  left: -5px;
  width: 10px !important;
}

.custom-dialog-connect {
  max-width: 410px;
  .bp4-dialog-header {
    min-height: 60px;
  }
  .bp4-heading {
    color: #081c36 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
}

.datepicker-unstyled .pt-input,
.datepicker-unstyled .pt-input:focus,
.datepicker-unstyled .pt-input.pt-active {
  box-shadow: none;
}

.input-border-secondary {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}

.p-5px-0px {
  padding: 5px 0px;
}

.mb15 {
  margin-bottom: 15px;
}

.icon-primary[class^='icon-'],
.icon-primary[class*=' icon-'] {
  &::before {
    color: #195fff;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.icon-radius {
  background-color: #195fff;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  width: 22px;
}

.pt-overlay-custom {
  .pt-transition-container {
    margin: 0px !important;
  }
}

.pt-popover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.forward-file {
  position: relative;
  img {
    max-height: 45px;
    width: auto;
  }
  .close {
    background-color: #9b9b9b;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    height: 18px;
    line-height: 18px;
    min-width: 18px;
    position: absolute;
    right: -6px;
    text-align: center;
    top: -6px;
    width: 18px
  }
  .forward-file-document {
    background-color: rgba(1, 1, 1, 0.1);
    border-radius: 5px;
    padding: 5px 10px;
  }
}
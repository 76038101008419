.cpc-tabs {
  .bp4-tab {
    color: var(--text-time);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 120%;
    padding-bottom: 5px;
    user-select: none;
  }
  .bp4-tab[aria-selected=true],
  .bp4-tab:not([aria-disabled=true]):hover {
    color: var(--blue);
    font-weight: 700;
    letter-spacing: 0;
  }
  .bp4-tab-panel {
    margin-top: 15px;
  }
  .bp4-tab-indicator-wrapper {
    padding-bottom: 5px;
    user-select: none;
    .bp4-tab-indicator {
      background-color: var(--blue);
      height: 2px;
    }
  }
  &.theme {
    &-1 {
      width: 100%;
      .bp4-tab-list > *:not(:last-child) {
        margin-right: 0;
      }
      .bp4-tab {
        color: #7589A3;
        font-weight: 700;
        justify-content: center;
        width: 50%;
        &[aria-selected=true], &:not([aria-disabled=true]):hover {
          color: #081C36;
        }
      }
    }
  }
  &.theme {
    &-2 {
      .bp4-tab {
        font-size: 14px;
      }
    }
  }
  &.theme {
    &-3 {
      border-top: 1px solid #f4f5f6;
      .bp4-tab {
        margin: 0;
        font-size: 13px;
        line-height: 19.5px;
        width: 33.33%;
        height: 40px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
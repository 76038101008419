$border-color: #e2e2e2;
$color-blue: #105aff;
$color-green: #41c656;
$color-arrow-select: #9fa1a5;
$bg-xam: #fdfdfd;
$table-border: #e2e2e2;
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@mixin center-full() {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}
.slider-status {
  margin: 20px;
  padding: 0;
  border: 1px dashed #c9cdd6;
  border-radius: 8px;
  overflow: hidden;
  li {
    align-items: center;
    background: #fbfcfe;
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 20px 39px 20px 20px;
    &:not(:last-child) {
      border-bottom: 1px solid #e4e8ed;
    }
    .status-smile {
      text-align: center;
      width: 100px;
      .icon {
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        height: 35px;
        width: 35px;
      }
      h4 {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #081c36;
        margin: 10px 0 0 0;
      }
    }
    .slider-bar {
      width: calc(100% - 363px);
      display: flex;
      align-items: center;
      span {
        background: #d8d8d8;
        border-radius: 30px;
        display: inline-block;
        height: 12px;
        position: relative;
        transition: 0.4s;
        vertical-align: text-top;
        width: calc(100% - 40px);
        .active {
          background: #1552dc;
          left: 0px;
          position: absolute;
          top: 0px;
          transition-timing-function: ease-in-out;
          width: 0%;
          z-index: 9;
        }
      }
      .num-count {
        display: inline-block;
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        text-align: right;
        width: 40px;
        color: #1552dc;
        margin-left: 26px;
      }
      &.slider-bar-two {
        width: calc(100% - 175px);
      }
    }
    .open-statistics {
      color: #1552dc;
      text-align: right;
      width: 188px;
      a {
        align-items: center;
        color: #1552dc;
        display: inline-flex;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        span.icon {
          color: #1552dc;
          font-size: 10px;
          margin-left: 5px;
        }
        .icon-arrow-right2 {
          padding-left: 6px;
        }
      }
    }
  }
}

.total-reviews-center {
  display: table;
  height: 100%;
  width: 100%;
  .total-reviews-center-box {
    display: table-cell;
    vertical-align: middle;
  }
}
.total-reviews {
  @include center-full();
  padding: 0 20px 20px 0;
  .img {
    background-color: $bg-xam;
    width: calc(100% - 214px);
    span {
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      height: 220px;
      width: 294px;
    }
  }
  .total {
    width: 214px;
    min-height: 233px;
    height: 100%;
    padding: 60px 40px;
    border: 1px dashed #c9cdd6;
    border-radius: 8px;
    span {
      color: $color-blue;
      display: inline;
      font-size: 60px;
      font-weight: 700;
      line-height: 84px;
      margin-bottom: 20px;
    }
    h4 {
      font-size: 14px;
      font-weight: 400;
      color: #1552dc;
      margin: 0px;
    }
  }
}

.statistics-popup-headding {
  border-bottom: 1px solid $border-color;
  background-color: #fff;
  padding: 10px 20px 5px 20px;
  .row {
    align-items: center;
    display: flex;
    .col-50 {
      flex: 0 0 50%;
      margin-bottom: 5px;
      padding: 0 0.5rem;
      span {
        font-size: 14px;
        font-weight: 700;
        &.wrap-total {
          display: flex;
          align-items: center;
          .total-feedback {
            color: #1552dc;
          }
          color: #1552dc;
        }
      }
      i {
        font-style: normal;
        font-weight: 400;
        margin-left: 12px;
        font-size: 24px;
        font-weight: 700;
        color: #1552dc;
      }
      .icon {
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        height: 32px;
        margin-right: 10px;
        vertical-align: middle;
        width: 32px;
      }
    }
  }
}

.statistics-popup-body {
  padding: 20px;
  background-color: #fff;
  h5 {
    font-size: 15px;
    font-weight: 700;
    line-height: 22.5px;
    margin: 0;
    margin-bottom: 13px;
    text-transform: uppercase;
  }
  .table-data-answer {
    border: none;
    margin-bottom: 0px;
    margin-right: 0px;
    tr {
      td {
        border: none;
        border-top: 1px solid $table-border;
        position: relative;
      }
      td:last-child {
        // border-left: 1px solid $table-border;
        &:before {
          background: $table-border;
          bottom: 50%;
          content: '';
          display: inline-block;
          height: 200px;
          left: 0px;
          margin-bottom: -100px;
          position: absolute;
          width: 1px;
        }
      }
      &:nth-child(2n) td {
        background: none;
      }
      &:first-child {
        td {
          border-top: none;
        }
      }
    }
  }
  .table.statistics-table {
    border-radius: 8px;
    overflow: hidden;
    border-top: 1px solid #e2e2e2;
    thead {
      border-top-left-radius: 8px;
      tr {
        th {
          background-color: #dde6fa;
        }
      }
    }
  }
}

.border-left {
  border-left: 1px solid $table-border;
}

.cpc-input-v2 {
  .bp4-input-action {
    height: 100%;
    min-width: 30px;
  }
  .left-icon {
    height: 30px;
    left: 0;
    line-height: 32px;
    margin-top: -15px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 30px;
    z-index: 1;
  }
  .right-element {
    .icon-xmark {
      background-color: #B6BEC9;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      font-size: 7px;
      font-weight: 400;
      height: 14px;
      line-height: 13.2px;
      margin-top: -7px;
      position: absolute;
      right: 5px;
      text-align: center;
      top: 50%;
      width: 14px !important;
    }
  }
  input {
    &:focus {
      border: 1px solid #1552DC!important;
    }
    &::placeholder {
      color: #A9B6C7;
      font-size: 13px;
    }
  }
  &.shape {
    &-circle {
      input {
        border-radius: 50%;
      }
    }
    &-round {
      input {
        border-radius: 8px;
      }
    }
    &-round-4 {
      input {
        border-radius: 4px;
      }
    }
  }
  &.theme {
    &-1 {
      input {
        border: 1px solid #E4E8ED;
        box-shadow: none;
        height: 37px;
        line-height: 37px;
      }
    }
    &-2 {
      input {
        border: 1px solid #E4E8ED;
        box-shadow: none;
        height: 33px;
        line-height: 33px;
      }
    }
    &-3 {
      input {
        border: 1px solid #E4E8ED;
        box-shadow: none;
        height: 32px;
        line-height: 32px;
      }
    }
  }
}
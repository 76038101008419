.cpc-form-group {
  .bp4-form-group {
    .bp4-text-muted {
      color: #c22649;
    }
  }
  &.theme {
    &-1 {
      .bp4-popover2-target {
        width: 100%;
      }
    }
  }
  .select-box {
    border-radius: 8px;
  }
  .tree-select-custom {
    height: 35px;
    padding: 4px 0;
    min-width: 206px;
    border-radius: 8px;
    align-items: center;
  }
}

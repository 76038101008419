.cpc-preload {
  .cpc-logo {
    position: absolute;
    top: 45%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
    background-image: url(./logo-preload.png);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    width: 88px;
    height: 88px;
  }

  .cpc-loading-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 45%;
    margin-top: 100px;
    font-size: 14px;
    color: #212121;
    left: 0;
  }

  .cpc-back-link {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 47.5%;
    margin-top: 100px;
    font-size: 14px;
    color: #212121;
  }

  .cpc-spinner {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 180px;
    height: 180px;
    margin-top: -90px;
    margin-left: -90px;
    border: 2px solid #425ff3;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
  }

  .cpc-spinner-2 {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 180px;
    height: 180px;
    margin-top: -90px;
    margin-left: -90px;
    border: 2px solid #425ff3;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 2s infinite ease-in-out;
    animation: sk-scaleout 2s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0.45);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0.45);
      transform: scale(0.45);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
}

.cpc-loading-ware {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .ware {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: wave;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    height: 10px;
  }
}

@keyframes wave {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;;
  }
}
.cpc-setting {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  &:hover {
    background-color: #0033a5;
  }
  .setting-avatar {
    cursor: pointer;
  }
}

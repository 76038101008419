.survey-search {
  padding: 20px 20px 0 20px;
  background-color: var(--gray1);
  .search-body {
    display: flex;
    align-items: flex-end;
    background-color: white;
    border-radius: 8px;
    gap: 15px;
    padding: 20px 20px 0 20px;
    .search {
      flex: 1;
    }
    .icon {
      display: flex;
      margin-bottom: 15px;
      height: 37px;
      width: 37px;
    }
  }
}

.cpc-icon {
  &::before {
    color: inherit !important;
  }
  &.cursor {
    &-default {
      cursor: default !important;
    }
  }
}

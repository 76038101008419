.color {
  &-blue {
    color: var(--blue) !important;
  }
  &-gray {
    color: var(--gray) !important;
  }
  &-gray2 {
    color: var(--gray2) !important;
  }
  &-gray5 {
    color: var(--gray5) !important;
  }
  &-gray7 {
    color: var(--gray7) !important;
  }
  &-gray8 {
    color: var(--gray8) !important;
  }
  &-red {
    color: var(--red) !important;
  }
  &-text-time {
    color: var(--text-time) !important;
  }
  &-orange {
    color: var(--orange) !important;
  }
}

.cpc-profile-section {
  position: absolute;
  width: 100%;
  height: 100%;

  .cpc-profile-header {
    background-image:linear-gradient(-149deg, #195fff 0%, #0044df 100%);
    height:293px;
    padding: 50px 20px 20px;
    text-align: center;
    font-family: 'Roboto';
    position: relative;

    .cpc-avatar {
      border:3px solid rgba(14,56,172,0.22);
      border-radius:4px;
      height: auto;
      margin-bottom: 12px;
      max-height: 100px;
      overflow: hidden;
      width:76px;
    }

    .avatar-img {
      border:3px solid rgba(14,56,172,0.22);
      border-radius:4px;
      height: 153px;
      margin-bottom: 12px;
      overflow: hidden;
      width:115px;
      vertical-align: middle;
      background-size: cover;
      background-position: center top;
      margin: 0 auto;
      background-repeat: no-repeat;

      img {
        display: none;
      }
    }

    h3 {
      font-size: 15px;
      color: #fff;
      font-weight: 700;
      margin-top: 10px;
    }

    h4 {
      opacity: 0.5;
      font-size: 13px;
      color: #fff;
      font-weight: 400;
      margin-bottom: 19px;
    }

    .cpc-overlay {
      position: absolute;
      opacity:0.23;
      background-image:linear-gradient(-180deg, #002da4 0%, #133ca6 100%);
      top: 0;
      left: 0;
      width:100%;
      height:100px;
      -moz-border-radius: 100% / 90px;
      -webkit-border-radius: 100% / 90px;
      border-radius: 100% / 90px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      -moz-border-top-right-radius: 0;
      -moz-border-top-left-radius: 0;
      -webkit-border-top-right-radius: 0;
      -webkit-border-top-left-radius: 0;
      z-index: 1;
    }

    .cpc-content {
      position: absolute;
      left: 0;
      top: 25px;
      width: 100%;
      z-index: 3;

      .pt-intent-primary {
        background: transparent;
        border:1px solid rgba(255,255,255,0.32);
        box-shadow: 1px 1px 3px 0 #1c3e94;
        border-radius:3px;
        span {
          font-size: 13px;
          font-family: 'Roboto';
          text-transform: uppercase;
        }
      }
    }

    .cpc-close {
      position: absolute;
      top: 8px;
      right: 5px;
      border: 0;
      background: transparent;
      cursor: pointer;

      .pt-icon {
        font-size: 25px;
        &::before {
          color: #fff;
        }
      }
    }
  }

  .cpc-profile-info {
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    height: calc(100% - 293px);

    .cpc-info-group {
      border-bottom: 1px solid #D9D9D9;
      padding-left: 55px;
      position: relative;
      &:last-child{
        border-bottom: 0px;
      }
      .btn-icon {
        background: none transparent;
        border: 0 none transparent;
        box-shadow: none;
      }
      .mt15 {
        margin-top: 15px;
      }
      .pt-fill {
        border: 1px solid #D9D9D9;
        border-radius: 3px;
        font-size: 13px;
        min-height: 40px;
        line-height: 1.4;
        margin-top: 0;
        padding: 10px 14px;
        width: 100%;
        &:focus {
          border-color: rgba(25, 95, 255, 0.6);
        }
      }
      .input-sort-form {
        max-width: 160px;
      }
      .form-group {
        position: relative;
        .pt-fill {
          padding-right: 35px;
        }
        .btn-submit-form {
          position: absolute;
          right: 5px;
          top: 10px;
        }
      }
      .cpc-icon {
        position: absolute;
        left: 20px;
        top: 15px;
        .left-icon  {
          font-size: 18px;
          &.icon-quote {
            font-size: 14px;
          }
          &.icon-call {
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
      .cpc-info {
        font-family: 'Roboto';
        padding-bottom: 18px;
        padding-right: 16px;
        padding-top: 18px;
        .title {
          font-size: 12px;
          color: #9b9b9b;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
        .text-link {
          color: #000000;
          &:hover {
            color: #0070ff;
          }
        }
        .main-info {
          color: #000000;
          font-size: 15px;
        }
        button {
          cursor: pointer;
        }
      }
      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }
}

.cpc-dialog {
  width: auto;
  &.max-720 {
    max-width: 720px;
    width: 100%;
  }
  &.max-500 {
    max-width: 500px;
    width: 100%;
  }
  &.max-400 {
    max-width: 400px;
    width: 100%;
  }
  &.max-900 {
    max-width: 900px;
    width: 100%;
  }
  &.min-auto {
    min-width: auto!important;
  }
  .bp4-dialog-header {
    .bp4-dialog-close-button {
      min-height: 20px;
      min-width: 20px;
    }
  }
  .bp4-dialog-body {
    margin: 0;
    padding: 0;
  }
  .bp4-dialog-body-scroll-container {
    max-height: calc(100vh - 40px);
  }
  &.theme {
    &-1 {
      border-radius: 12px !important;
      overflow: hidden !important;
    }
    &-2 {
      border-radius: 6px !important;
      overflow: hidden !important;
    }
  }
}

.cpc-avatar {
  background-position: center top;
  background-size: cover;
  border: 1px solid #f9f9f9;
  border-radius: 50%;
  height: 36px;
  position: relative;
  width: 36px;
  &.xs {
    height: 26px;
    width: 26px;
  }

  &.sm-1 {
    height: 32px;
    min-height: 32px;
    min-width: 32px;
    width: 32px;
  }

  &.sm-2 {
    height: 28px;
    min-height: 28px;
    min-width: 28px;
    width: 28px;
  }

  &.sm-2-medium {
    height: 31px;
    min-height: 31px;
    min-width: 31px;
    width: 31px;
  }

  &.sm {
    height: 36px;
    min-height: 36px;
    min-width: 36px;
    width: 36px;
  }
  &.sm-3 {
    height: 46px;
    min-height: 46px;
    min-width: 46px;
    width: 46px;
  }
  &.sm-4 {
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    width: 40px;
  }
  &.md {
    height: 53px;
    width: 53px;
  }

  &.lg {
    height: 68px;
    width: 68px;
  }
  &.online {
    &::after {
      background-color: var(--green);
      border: 2px solid white;
      border-radius: 50%;
      bottom: 0;
      content: '';
      height: 12px;
      position: absolute;
      right: 1px;
      width: 12px;
    }
  }
}

.wrapper-survey {
  background-color: var(--bg);
  position: relative;
  height: 100vh;
  overflow: hidden;

  .cpc-survey-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh - var(--header));
    padding-bottom: var(--header);
    &.header-fixed {
      margin-top: var(--header);
    }
  }

  .footer-action-block {
    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      .cpc-button {
        width: auto;
        box-shadow: none;
        .bp4-button-text {
          color: white;
        }
      }
      .btn-statistic {
        background-color: var(--green);
      }
      .btn-copy {
        background-color: var(--blue);
      }
    }
    .label-datepicker {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      margin: 0 14px 0 0;
    }
    .time-expire {
      display: flex;
      align-items: center;
      justify-content: center;
      .cpc-icon {
        margin-right: 10px;
        margin-left: 20px;
      }
      .expire {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        .date {
          margin-left: 5px;
        }
      }
    }

    .bp4-divider {
      height: 100%;
      opacity: 0.7;
    }

    .user-info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 3px 0;
        height: 36px;
        margin-right: 30px;
        .username {
          font-weight: 700;
          font-size: 13px;
          line-height: 16px;
        }
        .intro {
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          color: #000000;
          opacity: 0.5;
        }
      }
    }
    .btn-save {
      padding: 11px 20px;
      width: 90px;
      background-color: var(--blue);
      border-radius: 8px !important;
      height: 43px;
      &:hover {
        box-shadow: none;
        background-color: var(--blue);
      }
      span {
        color: white;
        font-size: 14px;
      }
    }
    .btn-cancel {
      padding: 11px 20px;
      width: 90px;
      background-color: #f2f2f2;
      border-color: #f2f2f2;
      border-radius: 8px !important;
      height: 43px;
      margin-right: 20px;
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
      span {
        color: #7589a3;
        font-size: 14px;
      }
    }
  }
}

.survey-statistic-header {
  display: flex;
  align-items: center;
  gap: 20px;

  padding: 20px;
  background-color: white;
  height: 66px;
  min-height: 66px;
  box-shadow: 0 -3px 25px rgba(0, 0, 0, 0.1), 0 -20px 30px rgba(0, 0, 0, 0.1);

  &.header-fixed {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 80px);
    z-index: 10;
  }

  .survey-name {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }

  .cpc-button-back {
    width: fit-content;
    box-shadow: none;
    .cpc-icon {
      transform: rotate(90deg);
    }
  }
}

.survey-content {
  width: 100%;
  padding: 0 20px !important;
  overflow-y: auto;
  .questions {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .question {
      display: flex;
      width: 100%;
      background-color: white;
      border-radius: 8px;

      .list {
        flex: 1;
        padding: 20px;
        border-right: 1px solid #eaeef4;
        .title {
          font-size: 14px;
          margin-bottom: 14px;
        }
        .list-answer {
          display: flex;
          flex-direction: column;
          gap: 11px;
          label {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: var(--text1);
            margin-bottom: 0;
          }
        }
      }
      .statistic {
        width: 420px;
        .statistical-container {
          padding: 20px;
        }
        .statistical-container:nth-child(2) {
          height: auto;
          border-top: 1px solid var(--border);
          border-radius: revert;
        }
      }
    }

    .statistical-container {
      padding: 20px;
    }
    .statistical-sum-wrap {
      border-radius: 8px;
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      padding: 0;
      .statistical-container:first-child {
        padding: 20px;
        flex: 1;
        margin: 0;
      }
      .statistical-container:last-child {
        border-left: 1px solid var(--border);
        padding: 20px;
        width: 420px;
      }
      .btn-view-all {
        height: 32px;
        border: 1px solid var(--blue);
        box-shadow: none;
        padding: 6px 16px;
        border-radius: 8px !important;
        .bp4-button-text {
          color: var(--blue);
          font-size: 14px;
          font-weight: 500;
        }
      }
      .list-user {
        .cpc-avatar {
          box-sizing: border-box;
          border: 2px solid white;
        }
        .rest-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--blue);
          color: white;
          font-weight: 800;
          font-size: 8px;
          line-height: 10px;
        }
        @mixin item-style($count: 6) {
          @for $i from 0 through $count - 1 {
            .item-#{$i} {
              $gap: 10;
              transform: translate(-($gap * $i) + px);
              //...
            }
          }
        }

        @include item-style();
      }
    }
  }

  .box {
    margin: 20px 0;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
  }

  .title {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .survey-title {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 21px;
  }
  .survey-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  .statistical-title {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
  .statistical-percent-user {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--text1);
  }
  .statistical-percent-number {
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    color: var(--blue);
  }
}

.statistic {
  .statistical-container {
    border: none;
    border-left: 1px solid var(--border);
    background-color: white;
    padding: 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    .attached-file {
      .cpc-file {
        a {
          flex: 1;
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }
    .statistical-title {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
    }
    .statistical-wrap-children {
      .wrap-chart-line-percent {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .chart-item {
          height: 20px;
          .chart-label {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: var(--text1);
          }
          .chart-item-processs {
            flex: 1;
          }
        }
      }
    }
  }
}

.list-user-tab-dialog {
  min-height: 449px;
  .list-user-tab {
    .react-tabs__tab {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      opacity: 0.3;
      padding: 6px 0;
      &.react-tabs__tab--selected {
        opacity: 1;
        color: var(--text);
      }
    }
  }
  .loadmore {
    .btn-load-more {
      width: auto;
      background-color: var(--green);
      color: white;
      box-shadow: none;
      .bp4-button-text {
        color: inherit;
      }
    }
  }
}

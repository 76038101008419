.cpc-contact-section {
  flex-grow: 1;
  height: 100%;
  position: relative;
  width: 100%;
  .bp4-tab-list {
    margin-top: 10px;
    .bp4-tab {
      font-size: 13px;
      line-height: 20px;
    }
    .bp4-tab[aria-selected="true"] {
      font-weight: 500;
    }
    .bp4-tab-indicator-wrapper {
      height: 25px !important;
    }
  }

  .cpc-input-search {
    position: relative;
    button {
      background: none;
      border: 0;
      margin: 0;
      margin-top: 2px;
      padding: 0;
      position: absolute;
      right: -8px;
      width: 18px;
      z-index: 10px;
      span::before {
        color: #c4c4c4;
      }
    }
  }

  .cpc-contact-list-container {
    bottom: 0;
    overflow: hidden;
    position: absolute;
    top: 145px;
    width: 100%;

    .cpc-search-result {
      height: 100%;
      left: 0;
      position: relative;
      top: 0;
      width: 100%;

      .cpc-not-found {
        color: #777777;
        font-family: "Roboto";
        font-size: 12px;
        padding: 14px 16px;
      }
    }
  }

  .pt-tab-list {
    border-bottom: 1px solid #ededed;
    padding-left: 16px;
    padding-right: 16px;

    .pt-tab-indicator-wrapper {
      display: none;
      height: 45px !important;
      padding-right: 10px;
      width: 28% !important;

      .pt-tab-indicator {
        background-color: #0070ff;
      }
    }

    .pt-tab {
      color: #000000;
      font-family: "Roboto";
      font-size: 12px;
      font-weight: 700;
      padding: 7px;
      text-align: center;
      text-transform: uppercase;
      width: 29%;

      &[aria-selected="true"],
      &:not([aria-disabled="true"]):hover {
        color: #195fff;
        font-weight: 500;
      }

      &[aria-selected="true"] {
        border-bottom: 2px solid #0070ff;
      }
    }
  }

  .container-fuild {
    border-bottom: 1px solid #ededed;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .cpc-search-form {
    font-family: "Roboto";

    input[type="text"] {
      border: 0;
      font-size: 13px;
      width: 100%;
    }
    button[type="submit"] {
      background: transparent;
      border: 0;
      cursor: pointer;
    }
    .pt-control input:checked ~ .pt-control-indicator {
      background: #195fff;
    }
  }

  .select-unit-search {
    .container-fuild {
      padding: 0;
    }
    .cpc-search-form {
      .btn-search {
        left: 7px;
        z-index: 5;
        .icon-search {
          &:before {
            color: #265fe1;
          }
        }
      }

      .close-button {
        background: none;
        border: 0;
        cursor: pointer;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 3px;
        top: 3px;
        width: 18px;
        z-index: 10px;
        span::before {
          color: #c4c4c4;
        }
      }

      .search-input-control {
        margin-left: 35px;
        max-width: 150px;
        padding-right: 20px;
      }

      .no-select-tree {
        width: 100%;
        .search-input-control {
          margin-left: 0;
          max-width: 100%;
          padding-left: 35px;
        }
      }

      .cpc-select {
        margin-top: -10px;
        .cpc-select-element {
          background: #fff;
          border: 1px solid #ededed;
          border-radius: 3px;
          box-shadow: none;
          -webkit-box-shadow: none;
          color: #454a52;
          font-family: "Roboto";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0;
          min-height: 35px;
          position: relative;
          text-align: left;
          width: 100%;
        }

        .rc-tree-select-selection--single,
        .rc-tree-select-selection--single:hover .rc-tree-select-selection--single:active {
          border: none;
          box-shadow: none;
          padding-top: 4px;
        }
        .rc-tree-select-arrow b {
          margin-top: 2px;
        }
      }

      .row {
        padding: 10px 0;
      }
      .row:first-child {
        padding-top: 0;
      }
      .search-group-form {
        border-bottom: 1px solid #ededed;
        display: flex;
        height: 36px;
        justify-content: space-between;
        padding-right: 15px;
        position: relative;
        .cpc-select {
          width: 115px;
        }
        .cpc-input-search {
          overflow: hidden;
        }
      }
      .check-status-row {
        padding: 12px 0;
        .checkbox-online {
          display: flex;
          gap: 12px;

          input[type="checkbox"] {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }

  .spinner {
    margin: 20px auto;
    text-align: center;
    width: 70px;
  }

  .spinner > div {
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    background-color: #999;

    border-radius: 100%;
    display: inline-block;
    height: 18px;
    width: 18px;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.rc-tree-select-dropdown {
  min-width: 270px !important;
  .rc-tree-select-tree-title {
    cursor: pointer;
  }
}

.footer-hotline {
  align-items: center;
  background: #e0e0e0;
  bottom: 0px;
  box-shadow: 0px -2px 4px rgba(0, 0, 31, 0.1);
  display: flex;
  left: 0px;
  min-height: 33px;
  padding: 0 15px;
  // .cpc-contact-section lien quan
  position: absolute;
  width: 100%;
  span {
    color: #d0021b;
    font-size: 16.31px;
    margin-right: 10px;
  }
  a {
    color: #d0021b;
    font-size: 14px;
    font-weight: 600;
    &:hover {
      color: #d0021b;
    }
    i {
      color: #333333;
      font-style: normal;
    }
  }
}

.download-app {
  padding: 10px 18px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 12px;
  border-radius: 6px;
  .app-img {
    width: 22px;
    height: 28px;
  }
  .download-body {
    color: var(--text);
    height: 91%;
    align-items: center;
    justify-content: space-between;
    .download {
      font-style: normal;
      font-weight: 500;
      font-size: 8px;
      line-height: 11px;
      text-transform: uppercase;
    }
    .notification {
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 11px;
      text-transform: uppercase;
    }
  }
}

.cpc-survey-box {
  .cpc-survey-link,
  .cpc-survey-link.pt-button:not([class*='pt-intent-']) {
    background: none 0 transparent;
    border: 0 none transparent;
    box-shadow: none;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    padding: 0;
    .icon-survey {
      background: none !important;
      color: #195fff;
      display: inline-block;
      float: left;
      font-size: 18px;
      margin-right: 5px;
      margin-top: 2px;
    }
  }
}
.cpc-survey-box-messenger {
  .cpc-survey-link,
  .cpc-survey-link.pt-button:not([class*='pt-intent-']) {
    background: none 0 transparent;
    border: 0 none transparent;
    box-shadow: none;
    cursor: pointer;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    padding: 0;
    padding-top: 10px;
    .icon-survey {
      background: none !important;
      color: #195fff;
      display: inline-block;
      float: left;
      font-size: 30px;
      margin-right: 5px;
      margin-top: 2px;
    }
    //loading
    .loading-data {
      background-position: center;
      background-size: 45px;
      display: inline-block;
      // background-image: url('/images/icon-loading.gif');
      float: left;
      height: 32px;
      margin-right: 5px;
      margin-top: 2px;
      width: 32px;
    }
    .cpc-survey {
      color: red;
      font-weight: bold;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .cpc-survey-name {
      display: block;
      overflow: hidden;
      text-align: left;
    }
  }
  .cpc-survey-link--inline {
    display: inline-block;
    .cpc-survey-inline {
      color: red;
      font-size: 14px;
      font-weight: 700;
      padding-left: 40px;
      &.text-blue {
        color: #1c69ff;
      }
    }
    .cpc-survey-inline-name {
      font-size: 14px;
      padding-left: 40px;
    }
  }
}
.mesage-item-sent {
  .cpc-survey-box {
    .cpc-survey-link {
      &:hover {
        color: #195fff;
      }
    }
  }
}
.cpc-chat-box-section {
  .cpc-survey-box {
    padding-bottom: 5px;
    .cpc-survey-link {
      background-color: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 3px;
      display: inline-block;
      padding: 6px 40px 6px 15px;
      position: relative;

      .cpc-cancel {
        background: 0 none transparent;
        border: 0 none transparent;
        box-shadow: none;
        color: #666;
        position: absolute;
        right: 10px;
        top: 5px;
      }
    }
  }
}
.cpc-chat-message-item {
  margin-bottom: 10px;
  &-flex {
    align-items: flex-start;
    display: flex;
    .message-item-flex-one {
      flex: 1;
    }
  }
  &::after {
    clear: both;
    content: '';
    display: block;
  }
  .cpc-new-item {
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 11px 0;
    position: relative;

    .cpc-new-item-label {
      background: #f0f3f6;
      border: 0;
      color: #d70000;
      display: inline-block;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 400;
      left: 0;
      padding: 4px 10px 4px 0;
      position: absolute;
      text-transform: uppercase;
      top: 0;
    }

    .cpc-new-item-line {
      border-top: 1px solid #ff4545;
    }
  }

  .cpc-item-avatar {
    background-position: center top;
    background-size: cover;
    border: 1px solid #d8d8d8;
    border-radius: 100%;
    cursor: pointer;
    float: left;
    height: 42px;
    margin-right: 14px;
    position: relative;
    width: 42px;
    .cpc-avatar {
      border: 1px solid #d8d8d8;
      border-radius: 100%;
      height: 100%;
      opacity: 0;
      width: 100%;
    }
    &.online {
      .cpc-icon-online {
        span {
          color: #7ed321;
        }
      }
    }
    .cpc-icon-online {
      bottom: -2px;
      margin: 0;
      padding: 0;
      pointer-events: none;
      position: absolute;
      right: 2px;

      span {
        color: #c4c4c4;
        font-size: 10px;
      }
    }
  }

  .cpc-item-container {
    overflow: hidden;
    .box-highlight {
      border: 1px solid #195fff;
    }

    .cpc-item-box {
      background: #fff;
      border: 1px solid #ededed;
      border-radius: 4px;
      min-height: 60px;
      padding: 5px 10px;
      position: relative;
      .cpc-check-delete {
        bottom: 5px;
        position: absolute;
        right: 0;
        z-index: 9;
        .pt-control {
          margin-bottom: 5px;
        }
      }
      .cpc-item-header {
        border-bottom: 1px solid #ededed;
        padding: 5px 50px 7px 0;
        position: relative;
        .important-message {
          left: 0;
          position: absolute;
          top: 4px;
        }
        .icon-star,
        .icon-star-no-fill {
          cursor: pointer;
          float: left;
          font-size: 18px;
          opacity: 0;
          overflow: hidden;
          -webkit-transform: translateY(10px);
          -ms-transform: translateY(10px);
          transform: translateY(10px);
          -webkit-transition: all 0.3s ease, width 0.3s ease 0.15s, margin 0.3s ease 0.15s,
            -webkit-transform 0.3s ease 0.3s;
          transition: all 0.3s ease, width 0.3s ease 0.15s, margin 0.3s ease 0.15s,
            -webkit-transform 0.3s ease 0.3s;
          transition: all 0.3s ease, transform 0.3s ease 0.3s, width 0.3s ease 0.15s,
            margin 0.3s ease 0.15s;
          transition: all 0.3s ease, transform 0.3s ease 0.3s, width 0.3s ease 0.15s,
            margin 0.3s ease 0.15s, -webkit-transform 0.3s ease 0.3s;
          width: 0;
          &.show {
            margin-right: 5px;
            opacity: 1;
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0);
            -webkit-transition: all 0.3s ease, opacity 0.3s ease 0.15s,
              -webkit-transform 0.3s ease 0.15s;
            transition: all 0.3s ease, opacity 0.3s ease 0.15s, -webkit-transform 0.3s ease 0.15s;
            transition: all 0.3s ease, transform 0.3s ease 0.15s, opacity 0.3s ease 0.15s;
            transition: all 0.3s ease, transform 0.3s ease 0.15s, opacity 0.3s ease 0.15s,
              -webkit-transform 0.3s ease 0.15s;
            width: 18px;
          }
        }
        .icon-star-no-fill::before {
          color: #ababab;
        }
        .cpc-sender {
          float: left;
          margin-right: 50px;
          h3 {
            color: #000000;
            cursor: pointer;
            display: inline-block;
            font-family: 'Roboto';
            font-size: 13px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 6px;
            margin-right: 5px;
          }
          .cpc-role {
            color: #000000;
            font-family: 'Roboto';
            font-size: 12px;
            margin-right: 5px;
            opacity: 0.8;
          }
        }
        .cpc-receivers {
          float: left;
          & > span {
            color: #000000;
            font-family: 'Roboto';
            font-size: 12px;
            font-weight: 500;
            margin-right: 8px;
          }
          .cpc-receivers-list {
            color: #000000;
            cursor: pointer;
            display: inline-block;
            font-family: 'Roboto';
            font-size: 12px;
            margin-right: 5px;
            opacity: 0.8;
            .cpc-name {
              color: #000;
            }
            .cpc-name::after {
              content: ', ';
            }
            .cpc-name:last-child::after {
              content: '';
            }
            .cpc-icon {
              cursor: pointer;
              &::before {
                color: #565656;
                font-size: 12px;
              }
            }
          }
        }
        .cpc-actions {
          display: inline-block;
          padding-top: 3px;
          &.cpc-icon {
            cursor: pointer;
            &::before {
              color: #797979;
              font-size: 13px;
            }
          }
        }
        .cpc-right-section {
          position: absolute;
          right: 0;
          text-align: right;
          top: 0;
          .cpc-time-delivery {
            margin-bottom: 5px;
            .cpc-time {
              color: #6f6f6f;
              font-family: 'Roboto';
              font-size: 13px;
              margin-left: 6px;
              margin-top: 1px;
              vertical-align: middle;
            }
            .cpc-delivery {
              .cpc-icon {
                vertical-align: middle;
                &::before {
                  color: #195fff;
                  font-size: 13px;
                  font-weight: 700;
                }
              }
            }
          }
          .cpc-sms-email {
            .cpc-sms {
              color: #454a52;
              font-family: 'Roboto';
              font-size: 10px;
            }
            .cpc-icon {
              font-size: 11px;
              margin-left: 8px;
              vertical-align: middle;
              &::before {
                color: #454a52;
              }
            }
          }
        }
        &:before,
        &:after {
          content: '';
          display: table;
        }
        &:after {
          clear: both;
        }
      }
      p:not(.sender) {
        font-family: 'Roboto' !important;
        font-size: 14px !important;
        line-height: 21px !important;
        margin: 0 !important;
        min-height: 21px;
        padding: 0 !important;
      }
      .cpc-item-body {
        font-family: 'Roboto';
        font-size: 14px;
        line-height: 1.4;
        padding-top: 6px;
        white-space: pre-line;
        word-wrap: break-word;
        * {
          font-family: 'Roboto' !important;
          font-size: 14px !important;
          line-height: 21px !important;
          margin: 0 !important;
          padding: 0 !important;
        }
        .emojione {
          width: 25px;
        }
      }
    }
  }
  &.cpc-owner {
    .cpc-item-avatar {
      background-position: center top;
      background-size: cover;
      border: 1px solid #d8d8d8;
      border-radius: 100%;
      float: right;
      height: 42px;
      margin-left: 14px;
      margin-right: 0;
      width: 42px;
    }

    .cpc-item-container {
      .cpc-item-box {
        background: #cfd7ff;
        border: 1px solid #cfd4f6;
        margin-left: 20px;
        min-height: 60px;

        .cpc-item-header {
          border-bottom-color: #bccbda;
          padding-right: 50px;
          position: relative;
          .cpc-sender {
            display: none;
          }
          .cpc-receivers {
            float: left;
          }
          &:before,
          &:after {
            content: '';
            display: table;
          }
          &:after {
            clear: both;
          }
        }

        .cpc-check-delete {
          bottom: 5px;
          position: absolute;
          right: 0;
          z-index: 9;
        }
      }
      .cpc-item-footer {
        .cpc-delivery {
          display: block;
        }
      }
    }
  }
}

.cpc-message-attachment {
  .cpc-message-attachment-item {

    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
    font-size: 13px;

    &.attachment-file {
      flex-direction: row;
      gap: 10px;
      .cpc-filename {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
        margin-right: 15px;
      }
      .cpc-filesize {
        color: #ffffff;
        font-size: 10px;
        font-weight: 400;
        line-height: 120%;
        opacity: 0.85;
      }
      .cpc-thumb {
        img {
          height: 32px;
          &.cpc-image {
            height: 138px;
          }
          &.cpc-image-v2 {
            height: auto;
            max-height: 180px;
            max-width: 100%;
          }
        }
      }
    }
    .bottom-file {
      display: block;

      margin-top: 10px;

      .file-name {
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
        width: 70%;
      }
    }

    .cpc-thumb {
      img {
        height: 32px;
        &.cpc-image {
          height: 138px;
        }
        &.cpc-image-v2 {
          height: auto;
          max-height: 180px;
          max-width: 100%;
        }
      }
    }
    .cpc-icon {
      cursor: pointer;
    }
    .cpc-filename {
      font-weight: 500;
      padding-top: 2px;
      a {
        color: #000000;
      }
    }
    .cpc-filesize {
      font-size: 12px;
      a {
        color: #000000;
      }
    }
  }
}

.ml-auto {
  margin-left: auto !important;
}
.mr20 {
  margin-right: 20px !important;
}

.transition-width-height {
  transition: width 0.3s ease, height 0.3s ease;
}
root {
  --deg: 0deg;
}
.rotate-img {
  -webkit-transform: rotate(var(--deg));
  -ms-transform: rotate(var(--deg));
  transform: rotate(var(--deg));
  transition: transform 0.3s ease;
}

.position-relative {
  position: relative;
}
.positon-absolute {
  position: absolute;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.absolute-center {
  left: 50%;
  line-height: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.overflow-hidden {
  overflow: hidden;
}

.float-left {
  float: left;
}

.cpc-chat-content {
  word-break: break-word;
}

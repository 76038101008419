.info-account {
  ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      gap: 12px;
      list-style: none;
    }
  }
  &__phone {
    b,
    a {
      color: #1552dc;
    }
  }
}

.cpc-flex {
  display: flex;
  > div {
    min-width: 0;
  }
  &.direction {
    &-row {
      flex-direction: row;
    }
    &-column {
      flex-direction: column;
    }
  }
  &.wrap {
    &-wrap {
      flex-wrap: wrap;
    }
    &-nowrap {
      flex-wrap: nowrap;
    }
  }
  &.align {
    &-center {
      align-items: center;
    }
    &-flex-start {
      align-items: flex-start;
    }
    &-flex-end {
      align-items: flex-end;
    }
  }
  &.justify {
    &-center {
      justify-content: center;
    }
    &-flex-start {
      justify-content: flex-start;
    }
    &-flex-end {
      justify-content: flex-end;
    }
    &-space-between {
      justify-content: space-between;
    }
    &-space-around {
      justify-content: space-around;
    }
  }
  &.gap {
    &-5 {
      gap: 5px;
    }
    &-10 {
      gap: 10px;
    }
    &-15 {
      gap: 15px;
    }
    &-20 {
      gap: 20px;
    }
    &-25 {
      gap: 25px;
    }
  }
}

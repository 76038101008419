.mention {
  background-color: var(--mentionBG)!important;
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--mentionColor)!important;
  cursor: pointer;
  font-size: 14px!important;
  line-height: 21px!important;
  margin: 0!important;
  padding: 0!important;
  > span {
    margin: 0;
    ::selection {
      background-color: transparent!important;
    }
  }
  &:active, &:focus {
    background-color: transparent!important;
  }
}

.ql-mention-list {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  .ql-mention-list-item {
    margin: 0;
    padding: 0;
    .mention-item {
      border-top: 1px solid #eaeef4;
      font-size: 14px;
      line-height: 1.3em;
      padding: 8px 15px;
      .mention-name {
        align-items: center;
        display: flex;
        gap: 10px;
        .avatar {
          background-color: #1552DC;
          border-radius: 50%;
          color: #fff;
          height: 35px;
          line-height: 35px;
          min-width: 35px;
          text-align: center;
          width: 35px;
        }
        img {
          border-radius: 50%;
          height: 35px;
          min-width: 35px;
          object-fit: cover;
          object-position: top center;
          width: 35px;
        }
        .name {
          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.3em;
            margin: 0;
          }
          span {
            color: #7589a3;
            font-size: 13px;
            line-height: 1.3em;
            &:last-child {
              margin-left: 5px;
            }
          }
          .all {
            color: #1552DC;
            font-weight: 400;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
.cpc-button-v2 {
  align-items: center;
  background-color: #eaeef4;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 5px;
  justify-content: center;
  padding: 0 5px;
  transition: 0.2s;
  .text-content {
    line-height: 100%;
  }
  &:hover {
    background-color: #e5e7e9;
  }
  &.size {
    &-xs {
      height: 28px;
      min-width: 28px;
    }
    &-xs-s {
      height: 32px;
      min-width: 32px;
      padding-left: 10px;
      padding-right: 10px;
    }
    &-xs-m {
      height: 36px;
      min-width: 36px;
      padding-left: 10px;
      padding-right: 10px;
    }
    &-sm-s {
      height: 40px;
      min-width: 40px;
      padding-left: 10px;
      padding-right: 10px;
    }
    &-sm {
      height: 43px;
      min-width: 43px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  &.shape {
    &-circle {
      border-radius: 50%;
    }
    &-round {
      border-radius: 20px;
    }
    &-round-sm {
      border-radius: 8px;
    }
    &-round-xs {
      border-radius: 4px;
    }
    &-round-6 {
      border-radius: 6px;
    }
  }
  &:disabled {
    background-color: rgba(211, 216, 222, 0.5) !important;
    box-shadow: none;
    color: rgba(95, 107, 124, 0.6);
    cursor: not-allowed;
    outline: none;
    .text-content {
      color: rgba(95, 107, 124, 0.6) !important;
    }
  }
  &.fill {
    display: flex;
    width: 100%;
  }
  &.minimal {
    background-color: transparent;
    height: auto;
    min-width: auto;
    padding: 0;
  }
  &.color {
    &-transparent {
      background-color: transparent;
    }
    &-red {
      background-color: #e80808;
      color: #fff;
      .text-content {
        color: #fff;
        font-weight: 600;
      }
      &:hover {
        background-color: #dc0707;
      }
    }
    &-red-text {
      color: #dc0707;
      .text-content {
        color: #dc0707;
      }
      &:hover {
        opacity: 0.9;
      }
    }
    &-light-red {
      background-color: rgba(232, 8, 8, 0.05);
      color: #e80808;
      .text-content {
        color: #e80808;
      }
      &:hover {
        background-color: rgba(232, 8, 8, 0.1);
      }
    }
    &-red-border {
      background-color: transparent;
      border: 1px solid #E80808;
      color: #fff;
      .text-content {
        color: #E80808;
      }
    }
    &-blue-border {
      background-color: transparent;
      border: 1px solid #1552DC;
      color: #1552DC;
      .text-content {
        color: #1552DC;
      }
    }
    &-gray {
      background-color: rgba(217, 217, 217, 0.35);
      color: #262626;
      .text-content {
        color: #262626;
      }
      &:hover {
        background-color: rgba(217, 217, 217, 0.54);
      }
    }
    &-light-gray {
      background-color: #f2f2f2;
      color: #7589a3;
      .text-content {
        color: #7589a3;
        font-weight: 600;
      }
      &:hover {
        background-color: #e4e3e3;
      }
    }
    &-light-gray-v2 {
      background-color: #f2f2f2;
      color: #081C36;
      .text-content {
        color: #081C36;
      }
      &:hover {
        background-color: #e4e3e3;
      }
    }
    &-light-gray-v3 {
      background-color: #f0f0f0;
      color: #606060;
      .text-content {
        color: #606060;
      }
      &:hover {
        background-color: #e6e6e6;
      }
    }
    &-grey {
      background-color: rgb(246, 247, 249);
      color: #222;
      .text-content {
        color: #222;
      }
      &:hover {
        background-color: rgb(234, 235, 236);
      }
    }
    &-light-blue {
      background-color: rgba(21, 82, 220, 0.10);
      .text-content {
        color: #1552DC;
      }
      &:hover {
        background-color: rgba(21, 82, 220, 0.20);
      }
    }
    &-blue {
      background-color: #1552dc;
      .text-content {
        color: #fff;
      }
      > i {
        color: #fff!important;
      }
      &:hover {
        background-color: #1142b6;
      }
    }
  }
  &.min {
    &-50 {
      min-width: 50px;
    }
    &-75 {
      min-width: 75px;
    }
    &-90 {
      min-width: 90px;
    }
  }
  &.hover {
    transition: 0.2s;
    &-zoom {
      &:hover {
        transform: scale(1.1)!important;
        transition-timing-function: linear;
      }
    }
    &-grey {
      &:hover {
        background-color: rgb(234, 235, 236);
      }
    }
    &-text-blue {
      &:hover {
        .text-content {
          color: #1552dc!important;
        }
        [class^="icon-"],
        [class*=" icon-"] {
          color: #1552dc!important;
        }
      }
      &:disabled {
        background-color: transparent!important;
        .text-content {
          color: #7589a3!important;
        }
        [class^="icon-"],
        [class*=" icon-"] {
          color: #7589a3!important;
        }
      }
    }
  }
}

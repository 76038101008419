.section-box-action {
  padding: 8px;
  min-width: 170px;
  .item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
    cursor: pointer;
    padding: 8px;
    .cpc-icon {
      width: 18px;
      text-align: center;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: var(--border);
    }

    .name {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #505050;
    }
    .delete {
      color: var(--red);
    }
  }
  .bp4-divider {
    border-bottom: 1px solid #eaeef4;
    border-right: 1px solid #eaeef4;
  }
}

.m-auto {
  margin: auto;
}

.bg-white {
  background-color: #fff;
}

.bg-yellow {
  background-color: #f6c42c;
}

.position-absolute {
  position: absolute;
}

.overflow-hidden {
  overflow: hidden;
}

.text-align-center {
  text-align: center;
}

.top-120 {
  top: 120px;
}

.z-index-1 {
  z-index: 1;
}

.z-index--2 {
  z-index: -2;
}

.stage-blue::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 300px;
  background-color: #3c3cd4;
  border-radius: 50%;
  bottom: -180px;
  z-index: -1;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-3 {
  border-radius: 3px;
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.m0 {
  margin: 0 !important;
}

.p40 {
  padding: 20px !important;
}

.list-style-none {
  list-style: none;
}

.p0 {
  padding: 0 !important;
}

.border-gray {
  border: 1px solid #e4e4e8;
}

.shadow-black {
  -webkit-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.line-height-30 {
  line-height: 30px;
}

.max-width-1000 {
  max-width: 1000px;
}

.bg-bottom {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.top-0 {
  top: 0;
}

.bottom-100 {
  bottom: 100%;
}

.bottom-100--15 {
  bottom: calc(100% - 15px);
}

.balloon-animate {
  -webkit-animation: balloon1 6s ease-in-out infinite;
  animation: balloon1 6s ease-in-out infinite;
}

.balloon-animate-2 {
  -webkit-animation: balloon2 6s ease-in-out infinite;
  animation: balloon2 6s ease-in-out infinite;
}

.heartbeat {
  -webkit-animation: heartbeat 2s ease-out infinite both;
  animation: heartbeat 2s ease-out infinite both;
}

.fade-in-top {
  opacity: 0;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
}

.transition-300 {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.min-height-100px {
  min-height: 100px;
}
.max-height-100px {
  max-height: 100px;
}

.max-height-450px {
  max-height: 450px;
}

.max-height-515px {
  max-height: 515px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.top-65px {
  top: 65px;
}

.h-178px {
  height: 178px;
}

.font-weight-bold {
  font-weight: bold;
}

.mb-45px {
  margin-bottom: 45px;
}

.box-shadow-material {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.hover-scale {
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  &:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.hover-select {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
    border-color: #2a62e2;
  }
}

.rounded {
  border-radius: 50%;
}
.w-60px {
  width: 60px;
}
.h-60px {
  height: 60px;
}
.w-49px {
  width: 49px;
}
.h-49px {
  height: 49px;
}
.w-90px {
  width: 90px;
}
.h-90px {
  height: 90px;
}
.bg-cover {
  background-size: cover;
}
.bg-center {
  background-position: center;
}
.left-200px {
  left: 200px;
}
.top-40px {
  top: 40px;
}
.pt-5 {
  padding-top: 5px;
}

.happy-birthday > span:nth-child(even) {
  color: #6a3367;
}

.happy-birthday > span:nth-child(odd) {
  color: #e7754b;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.icon-white {
  &::before {
    color: #fff !important;
  }
}

@-webkit-keyframes balloon2 {
  0%,
  100% {
    -webkit-transform: translate(0, -10px) rotate(6eg);
    transform: translate(0, -10px) rotate(6eg);
  }
  50% {
    -webkit-transform: translate(-20px, 30px) rotate(-8deg);
    transform: translate(-20px, 30px) rotate(-8deg);
  }
}

@keyframes balloon2 {
  0%,
  100% {
    -webkit-transform: translate(0, -10px) rotate(6eg);
    transform: translate(0, -10px) rotate(6eg);
  }
  50% {
    -webkit-transform: translate(-20px, 30px) rotate(-8deg);
    transform: translate(-20px, 30px) rotate(-8deg);
  }
}

@-webkit-keyframes balloon1 {
  0%,
  100% {
    -webkit-transform: translate(10px, -10px) rotate(-8eg);
    transform: translate(10px, -10px) rotate(-8eg);
  }
  50% {
    -webkit-transform: translate(-15px, 10px) rotate(10deg);
    transform: translate(-15px, 10px) rotate(10deg);
  }
}

@keyframes balloon1 {
  0%,
  100% {
    -webkit-transform: translate(10px, -10px) rotate(-8eg);
    transform: translate(10px, -10px) rotate(-8eg);
  }
  50% {
    -webkit-transform: translate(-15px, 10px) rotate(10deg);
    transform: translate(-15px, 10px) rotate(10deg);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-2-26 22:34:42
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.birthday-left {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 515px;
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .text-congratulation {
    max-width: 194px;
    margin-top: 35px;
  }
  .date {
    font-size: 19px;
    line-height: 150%;
    font-weight: 400;
    color: #fff;
    font-style: italic;
    margin-top: -10px;
  }
}
.birthday-right {
  width: 540px;
  background-color: #fff;
  .avatar {
    padding: 12px;
  }
  .name {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }
  .short-name {
    font-size: 13px;
    line-height: 12px;
    color: #000;
    opacity: 0.5;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .year {
    font-size: 13px;
    line-height: 12px;
    margin-bottom: 14px;
    color: #000;
    opacity: 0.5;
  }
}

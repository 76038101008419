.bp4-toast {
  .bp4-button {
    color: #fff;
  }
  &.bp4-intent-success, &.bp4-intent-primary, &.bp4-intent-danger {
    .bp4-toast-message {
      color: #fff;
    }
    .bp4-button {
      &:last-child > .bp4-icon-cross {
        svg {
          fill: #fff!important
        }
      }
    }
  }
}
.cpc-not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  .image {
    max-height: 85px;
  }
  .text {
    font-size: 13px;
    margin: 0;
    margin-top: 15px;
    opacity: 0.4;
  }
  &.theme {
    &-1 {
      .image {
        max-height: 60px;
      }
    }
  }
}
.cpc-unit-item {
  border-bottom: 1px solid #ededed;
  padding: 10px 0 0 0;

  &.active {
    .cpc-unit-info {
      font-weight: 500;
    }
  }

  .loading {
    float: left;
    width: 26px;
    min-height: 30px;
  }

  .cpc-unit-item {
    padding-left: 20px;
  }

  .cpc-unit-item:last-child {
    border-bottom: none;
  }

  .cpc-department-section {
    min-height: auto;
  }

  .cpc-item {
    font-size: 13px;
    color: #000000;
    font-weight: 400;
    position: relative;
    padding-left: 33px;
    padding-right: 16px;
    display: flex;
    align-items: flex-start;
    &.disabled {
      cursor: default;
      .unit-block {
        opacity: 0.5;
      }
    }

    .cpc-unit-info {
      font-family: "Roboto";
    }

    .pt-checkbox {
      float: left;
    }

    .cpc-unit-info {
      float: left;
      cursor: pointer;
      margin-top: 1px;
      margin-bottom: 10px;
      max-width: 75%;
      width: auto;
    }

    .pt-control input:checked ~ .pt-control-indicator {
      background: #195fff;
    }

    .toggle-button {
      position: absolute;
      left: 0;
      top: 0;
    }

    button {
      border: 0;
      background: transparent;
      cursor: pointer;
      height: 18px;
      margin-top: -2px;

      span {
        font-size: 16px;
        color: #195fff;
      }

      .pt-icon {
        &::before {
          color: #9fa2a5;
        }
      }
    }
  }
}
.unit-block {
  flex-grow: 1;
  display: flex;
  gap: 12px;
  & > div {
    flex: 1;
  }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border: 4px solid #fff;
  border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid #fff;
  border-radius: 16px;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.scrollbar-thumb-vertical {
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
  right: -2px;
}

$border-color: #e2e2e2;
$color-blue: #105aff;
$color-green: #41c656;
$color-arrow-select: #9fa1a5;
@mixin center-full() {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}
// Margin helper classes
@for $i from 0 through 13 {
  $value: $i * 5;
  $unit: '';
  @if $i != 0 {
    $unit: 'px';
  }

  .m#{$i*5} {
    margin: #{$value}#{$unit} !important;
  }
  .mt#{$i*5} {
    margin-top: #{$value}#{$unit} !important;
  }
  .mr#{$i*5} {
    margin-right: #{$value}#{$unit} !important;
  }
  .mb#{$i*5} {
    margin-bottom: #{$value}#{$unit} !important;
  }
  .ml#{$i*5} {
    margin-left: #{$value}#{$unit} !important;
  }
}
// Padding helper classes
@for $i from 0 through 13 {
  $value: $i * 5;
  $unit: '';
  @if $i != 0 {
    $unit: 'px';
  }

  .p#{$i*5} {
    padding: #{$value}#{$unit} !important;
  }
  .pt#{$i*5} {
    padding-top: #{$value}#{$unit} !important;
  }
  .pr#{$i*5} {
    padding-right: #{$value}#{$unit} !important;
  }
  .pb#{$i*5} {
    padding-bottom: #{$value}#{$unit} !important;
  }
  .pl#{$i*5} {
    padding-left: #{$value}#{$unit} !important;
  }
}

// column width %
@for $i from 0 through 100 {
  $value: $i;
  $unit: '';
  @if $i != 0 {
    $unit: '%';
  }

  .column-#{$i} {
    width: #{$value}#{$unit};
  }
}

// css loading button
.loading-rotate {
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

button:enabled {
  cursor: pointer;
}
button:disabled {
  background-color: #f7f7f7;
  cursor: not-allowed;
}

.center {
  text-align: center;
}
.bg-white {
  background: #fff;
}
.border-rbl {
  border-bottom: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
}

.button-action {
  background: #fff;
  border: 1px solid $border-color;
  border-radius: 3px;
  box-shadow: none;
  color: #333333;
  cursor: pointer;
  min-height: 40px;
  padding: 0 15px;
  .button-inside {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 -3px;
    &.button-inside-reverse {
      flex-direction: row-reverse;
    }
    span {
      padding: 0 3px;
    }
    .title {
      font-size: 13.1px;
      font-weight: 500;
      white-space: nowrap;
    }
    .icon {
      color: #818181;
      font-size: 12px;
    }
  }
  &.blue {
    background-color: $color-blue;
    color: #fff;
    .icon {
      color: #fff;
      &:before {
        color: #fff;
      }
    }
  }
  &.white {
    color: #333333;
    .icon {
      color: #818181;
    }
  }
}

.go-back {
  .icon:before {
    color: #818181;
  }
}

.cpc-container-wrapper {
  font-size: 13px;
  line-height: 1.4rem;
  padding: 65px 20px 0 20px;
  &.container-wrapper-max-width {
    margin: 0 auto;
    max-width: 1366px;
  }
}

.cpc-heading-page {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  .cpc-heading-page-col {
    align-items: center;
    display: flex;
  }
}

.heading-extra {
  align-items: center;
  display: flex;
  margin: 0 -5px;
  .heading-extra-col {
    padding: 0 5px;
  }
  .extra-button {
    background: #195fff;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    height: 40px;
    text-transform: uppercase;
    .title {
      color: #fff;
    }
    .icon {
      color: #fff;
    }
    &.green {
      background: #41c656;
    }
  }
}

.title-icon {
  align-items: center;
  display: flex;
  margin-left: 20px;
  [class^='icon-'],
  [class*=' icon-'] {
    color: #175bf5;
    font-size: 26px;
  }
  .title {
    color: #175bf5;
    font-size: 20px;
    font-weight: 500;
    margin: 0px;
    margin-left: 10px;
  }
}

.cpc-panel {
  background: #fff;
  margin-bottom: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .panel-title {
    align-items: center;
    display: flex;
    padding: 20px 0 16px 0;
    margin: 0 20px;
    border-top: 4px solid #f4f5f6;
    [class^='icon-'],
    [class*=' icon-'] {
      color: #175bf5;
      font-size: 16px;
    }
    .title {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      margin: 0px;
    }
  }
  .panel-body {
    .feedback-form {
      padding: 0 20px;
      .form-group-control {
        .select-box {
          border-radius: 8px;
          select {
            height: 37px;
            border-radius: 8px;
          }
        }
        textarea {
          border-radius: 8px;
        }
      }
    }
  }
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}
.border-top {
  border-top: 1px solid $border-color;
}

.smile-title {
  font-size: 17px;
  font-weight: 500;
}
.smile-evaluate {
  @include center-full();
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  li {
    list-style: none;
    margin-bottom: 20px;
    padding: 0 10px;
    .smile-box {
      @include center-full();
      border: 1px solid $border-color;
      border-radius: 4px;
      cursor: pointer;
      flex-direction: column;
      height: 91px;
      min-width: 200px;
      position: relative;
      &:hover {
        background: #ddd;
      }
      .icon {
        height: 32px;
        width: 32px;
        background-size: contain;
        background-repeat: no-repeat;
      }
      h4 {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 8px;
      }
      i {
        background: #6ac259;
        border-radius: 50%;
        color: #fff;
        display: block;
        font-size: 12px;
        height: 23px;
        line-height: 23px;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 10px;
        width: 23px;
      }
    }
  }
}

.form-group-control {
  margin-bottom: 20px;
  label {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    position: relative;
    &.request:after {
      color: #d0021b;
      content: '*';
      display: inline-block;
      position: absolute;
      right: -10px;
      top: 0px;
    }
    &.label-title {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 14px;
      text-transform: uppercase;
    }
    i {
      margin-left: 5px;
    }
  }
  textarea {
    border: 1px solid $border-color;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
  }
  .input-choose-file {
    overflow: hidden;
    position: relative;
    width: 100%;
    input[type='text'] {
      border: 1px solid $border-color;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      box-sizing: border-box;
      height: 40px;
      line-height: 40px;
      outline: none;
      padding: 0 10px;
      padding-right: 30px;
      width: calc(100% - 75px);
    }
    input[type='file'] {
      bottom: 0px;
      cursor: pointer;
      left: 0px;
      opacity: 0;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 100%;
      z-index: 7;
    }
    button {
      background: #105aff;
      border: none;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      color: #fff;
      display: inline-block;
      font-weight: 500;
      height: 40px;
      line-height: 40px;
      position: absolute;
      right: 0px;
      text-align: center;
      top: 0px;
      width: 75px;
    }
  }
}

.form-footer {
  border-top: 1px solid $border-color;
  padding: 15px 20px 10px;
}

.button-group {
  flex-wrap: wrap;
  margin: 0px -5px;
  text-align: left;
  &.center {
    @include center-full();
  }
  .button-item {
    display: inline-block;
    margin-bottom: 5px;
    padding: 0 5px;
  }
}

.border-wrapper {
  border: 1px solid $border-color;
}
.row-filter {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  transition: 0.2s;
  .col-filter {
    padding: 0 5px;
  }
  .col-filter-glow {
    flex-grow: 1;
  }
  label {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 6px;
  }
}
.filter-wrapper {
  background-color: #f9faff;
  padding: 10px 10px 0 10px;
}
.row-picker-range {
  display: flex;
  flex-wrap: nowrap;
  max-width: 290px;
  .col-picker-range:last-child {
    .filter-control-date {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      margin-left: -1px;
    }
  }
  .col-picker-range:first-child {
    .filter-control-date {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
}
.filter-control {
  border: 1px solid #dfe1e4;
  border-radius: 3px;
  display: flex;
  margin-bottom: 10px;
  position: relative;
  input,
  select {
    border-color: transparent;
    box-shadow: none !important;
    color: #333333;
    font-weight: 400;
    height: 38px;
    line-height: 38px;
    margin: 0px;
    outline: none;
    padding: 0 10px;
    width: 100%;
  }
  &.filter-control-date {
    input {
      background-image: url('table-filter-date.svg');
      background-position: calc(100% - 10px) center;
      background-repeat: no-repeat;
    }
    .pt-input-group:last-child {
      border-left: 2px solid #dfe1e4;
    }
  }
  &.filter-control-search {
    position: relative;
    input {
      min-width: 250px;
      width: calc(100% - 40px);
    }
    button {
      background: $color-green;
      border-bottom-left-radius: 0;
      border-color: transparent;
      border-top-left-radius: 0;
      box-shadow: none;
      color: #fff;
      height: 40px;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 40px;
      .icon:before {
        color: #fff;
      }
      &:hover {
        background: $color-green;
      }
    }
  }
  .filter-close-icon {
    cursor: pointer;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
  }
  &.filter-control-search-button {
    .filter-close-icon {
      right: 50px;
    }
  }
  &.filter-control-select {
    border: 0px;
    min-width: 200px;
    .rc-tree-select-selection {
      height: 38px;
      line-height: 38px;
      &.rc-tree-select-selection--single .rc-tree-select-selection__rendered {
        line-height: 38px;
      }
    }
  }
  &.filter-control-fix-width {
    max-width: 157px;
    min-width: 157px;
  }
  &.filter-control-fix-width2 {
    max-width: 198px;
    min-width: 198px;
  }
  &.filter-control-reset {
    button {
      border-color: transparent;
      box-shadow: none;
      height: 38px;
      width: 38px;
      &:hover {
        box-shadow: none;
      }
    }
    .rotate {
      transform: rotate(90deg);
      &.rotate-toggle {
        transform: rotate(270deg);
      }
    }
  }
  &.filter-control-toggle {
    button {
      color: #8a8a8a;
      font-size: 11px;
    }
  }
}

.status {
  border: 1px solid #ddd;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  white-space: nowrap;
  &.warning {
    background-color: #fff2d6;
    border-color: #f1d393;
    color: #d19330;
  }
  &.danger {
    background-color: #fed8da;
    border-color: #ffacb1;
    color: #f60a18;
  }
  &.danger {
    background-color: #fed8da;
    border-color: #ffacb1;
    color: #f60a18;
  }
  &.success {
    background-color: #e5f5df;
    border-color: #9ee782;
    color: #5ca73f;
  }
  &.info {
    background-color: #eef6ff;
    border-color: #adc6ff;
    color: #326ef6;
  }
}

.donViNguoiGui {
  p {
    margin-bottom: 4px;
  }
  span {
    color: #757575;
  }
}

.avatar-info {
  align-items: center;
  display: flex;
  &.small {
    .image {
      height: 32px;
      width: 32px;
    }
  }
  .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    height: 36px;
    margin-right: 10px;
    overflow: hidden;
    width: 36px;
  }
  .name {
    flex-basis: 1;
    font-size: 13px;
    font-size: 500;
    margin: 0px;
    p {
      margin: 0px;
    }
    .donViPhongBan {
      color: #757575;
    }
  }
}

.file-list {
  margin: 0px;
  padding: 0px;
  li {
    list-style: none;
    a {
      align-items: center;
      color: #182026;
      display: inline-flex;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.4rem;
      .file-list-icon {
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 20px;
        margin-right: 10px;
        width: 17px;
      }
      .file-list-name {
        flex: 1;
      }
      i {
        font-style: normal;
        margin-left: 5px;
        &:before {
          content: '-';
          margin-right: 5px;
        }
      }
      &:hover {
        color: $color-blue;
      }
    }
  }
}

.tabs-list {
  align-items: flex-end;
  background: #fff;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  border-top: 1px solid $border-color;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 0px;
  padding: 0px;
  position: relative;
  li {
    cursor: pointer;
    list-style: none;
    padding: 12px 40px;
    position: relative;
    .tabs-list-box {
      align-items: center;
      display: flex;
      margin: 0 -5px;
    }
    &::before {
      background: transparent;
      bottom: 0px;
      content: '';
      display: inline-block;
      height: 4px;
      left: 50%;
      opacity: 0;
      position: absolute;
      transition: 0.2s;
      width: 0;
      z-index: -1;
    }
    &.active:before,
    &:hover::before {
      background-color: $color-blue;
      left: 0px;
      opacity: 1;
      transition-timing-function: ease-in-out;
      width: 100%;
      z-index: 1;
    }
    span {
      padding: 0 5px;
    }
  }
}

.question-block {
  background: #fff;
  border: 1px solid $border-color;
  margin-bottom: 7px;
  margin-top: -1px;
}
.question-box {
  width: 100%;
  background-color: #fff;
  padding: 20px 20px 15px 47px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .question-add-title {
    border: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4rem;
    margin-bottom: 12.5px;
    width: 100%;
    &::-webkit-input-placeholder {
      font-weight: 500;
    }
    &:-ms-input-placeholder {
      font-weight: 500;
    }
    &::placeholder {
      font-weight: 500;
    }
  }
  .pt-control {
    line-height: 17px;
    margin-bottom: 15px;
  }
  .question-add {
    align-items: center;
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #e4e8ed;
    input {
      font-size: 14px;
      line-height: 21px;
      padding: 6px 12px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #e4e8ed;
      &::-webkit-input-placeholder {
        color: #a9b6c7;
        font-size: 14px;
      }
    }
  }
  .question-radio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #b6b6b6;
      cursor: default;
      font-size: 18px;
    }
    &__left {
      display: flex;
      align-items: center;
      width: 100%;
      .icon-radio-custom {
        width: 16px;
        height: 16px;
        min-width: 16px;
      }
      .input-text-custom {
        width: 100%;
        height: 18px;
        border: none;
        outline: none;
        padding: 0;
        font-size: 13px;
      }
    }
  }
}
.question-footer {
  padding: 15px 20px 20px 47px;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .button-group {
    flex-wrap: wrap;
    margin: 0px -5px;
    text-align: left;
    &.center {
      @include center-full();
    }
    .button-item {
      display: inline-block;
      margin-bottom: 5px;
      padding: 0 5px;
      span {
        font-size: 14px;
        line-height: 37px;
      }
      .button-action {
        border-radius: 8px;
        height: 37px;
        min-height: 37px;
      }
      &.add {
        .button-action {
          border: 1px solid #00aa48;
          span {
            color: #00aa48;
          }
        }
      }
      &.delete {
        span {
          color: #7589a3;
        }
      }
    }
  }
}

.dialog-common {
  border-radius: 3px !important;
  padding: 0 !important;
  width: auto !important;
  .pt-dialog-header {
    border-radius: 3px 3px 0 0 !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    .pt-dialog-close-button {
      padding-bottom: 4px;
      padding-top: 4px;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      line-height: 1.5em !important;
    }
  }
}

.dialog-statistics {
  border-radius: 6px !important;
  background-color: transparent !important;
  overflow: hidden;
  .bp4-dialog-header {
    background-color: #1552dc;
    padding: 12px 0 12px 15px;
    height: 48px;
    .bp4-heading {
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 400;
    }
    .bp4-dialog-close-button {
      svg {
        fill: #fff !important;
      }
    }
  }
}

.sortable {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    padding: 0;
    position: relative;
  }
}
.sortable-li {
  list-style: none;
  padding: 0;
  position: relative;
}
.sortable-handle {
  cursor: move;
  text-align: center;
  position: absolute;
  left: 20px;
  top: 20px;
  .sortable-icon {
    display: block;
    font-size: 14px;
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 1366px) {
  .cpc-container-wrapper {
    &.container-wrapper-max-width {
      max-width: 800px;
    }
  }
}

.loading-row {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-name: wave;
  -webkit-animation-name: wave;
  -moz-animation-name: wave;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  background: #dddddd;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#dddddd),
    color-stop(0.2, #f5f5f5),
    color-stop(0.4, #e5e5e5),
    to(#dddddd)
  );
  background-image: -webkit-linear-gradient(
    left,
    #dddddd 0%,
    #f5f5f5 20%,
    #e5e5e5 40%,
    #dddddd 100%
  );
  background-image: -moz-gradient(
    linear,
    left center,
    right center,
    from(#dddddd),
    color-stop(0.2, #f5f5f5),
    color-stop(0.4, #e5e5e5),
    to(#dddddd)
  );
  background-image: -moz-linear-gradient(left, #dddddd 0%, #f5f5f5 20%, #e5e5e5 40%, #dddddd 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 15px;
  position: relative;
  width: 100%;
}
.loadmore {
  clear: both;
  padding: 20px 15px 0;
  text-align: center;
}

.cpc-container {
  padding: 20px 26px;
  &.pb-65 {
    padding-bottom: 65px;
  }
  .cpc-content {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 20px;
    &.configuration {
      padding: 0;
    }
    &.left {
      border-radius: 0;
      border-right: 1px solid $border-color;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      width: 60%;
    }
    &.right {
      border-radius: 0;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      width: 40%;
    }
    &__label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
      &.statistic {
        padding: 20px;
        font-weight: 700;
        margin-bottom: 0;
        border-bottom: 1px solid #e4e8ed;
        width: 100%;
        .text-red {
          color: red;
        }
      }
      &.mb14 {
        margin-bottom: 14px;
      }
    }
    &__body {
      width: 100%;
      .quality-list {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin: 0px;
        padding: 0px;
        li {
          list-style: none;
          .smile-box {
            @include center-full();
            background-color: #fbfcfe;
            border: 1px dashed $border-color;
            border-radius: 8px;
            cursor: pointer;
            flex-direction: column;
            height: 91px;
            min-width: 200px;
            padding: 15px;
            position: relative;
            &.active {
              background: #eff4ff;
              border: 1px solid #1552dc;
            }
            &:hover {
              background: #ddd;
            }
            .icon {
              height: 32px;
              width: 32px;
              background-size: contain;
              background-repeat: no-repeat;
            }
            h4 {
              font-size: 13px;
              font-weight: 500;
              margin-top: 8px;
              line-height: 20px;
            }
            .num-count {
              color: #1552dc;
              font-size: 36px;
              font-weight: 700;
              margin-top: 10px;
            }
            i {
              background: #6ac259;
              border-radius: 50%;
              color: #fff;
              display: block;
              font-size: 12px;
              height: 23px;
              line-height: 23px;
              position: absolute;
              right: 10px;
              text-align: center;
              top: 10px;
              width: 23px;
            }
            &.no-action {
              cursor: default;
              &:hover {
                background-color: #fbfcfe;
              }
            }
          }
        }
      }
    }
    textarea {
      border: 1px solid $border-color;
      border-radius: 4px;
      padding: 10px;
      width: 100%;
    }
    .input-choose-file {
      overflow: hidden;
      position: relative;
      width: 100%;
      input[type='text'] {
        border: 1px solid $border-color;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        outline: none;
        padding: 0 10px;
        padding-right: 30px;
        width: calc(100% - 75px);
      }
      input[type='file'] {
        bottom: 0px;
        cursor: pointer;
        left: 0px;
        opacity: 0;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 100%;
        z-index: 7;
      }
      button {
        background: #105aff;
        border: none;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        color: #fff;
        display: inline-block;
        font-weight: 500;
        height: 40px;
        line-height: 40px;
        position: absolute;
        right: 0px;
        text-align: center;
        top: 0px;
        width: 75px;
      }
    }
  }
  .form-detail {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    .form-detail-item {
      margin-left: 15px;
      margin-right: 15px;
    }
    .ml-8 {
      margin-left: 8px;
    }
    &.hide-bottom-radius {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &__header {
      align-items: center;
      display: flex;
      gap: 8px;
      justify-content: flex-start;
      margin-bottom: 15px;
      padding-bottom: 16px;
    }
    .form-detail-group {
      &.question {
        border-top: 4px solid #f4f5f6;
        padding-top: 20px;
        .question-list {
          margin-top: 20px;
          .question-item {
            font-size: 14px;
            &__label {
              display: inline-block;
              font-weight: 500;
              margin-bottom: 8px;
            }
            &__value {
              background: #f3f6fd;
              border-radius: 8px;
              display: flex;
              gap: 8px;
              justify-content: flex-start;
              line-height: 21px;
              padding: 12px;
              &:not(:last-child) {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
      &__label {
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 8px;
        &.collapse-label {
          cursor: pointer;
          .drop-icon {
            display: inline-block;
            margin-left: 8px;
            transition: all 0.2s linear;
            &.open {
              transform: rotate(180deg);
            }
          }
        }
      }
      &__value {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 20px;
      }
      .avatar-info {
        align-items: center;
        display: flex;
        &.small {
          .image {
            height: 26px;
            width: 26px;
          }
        }
        .image {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 50%;
          height: 36px;
          margin-right: 10px;
          overflow: hidden;
          width: 36px;
        }
        .name {
          flex-basis: 1;
          font-size: 14px;
          font-size: 500;
          margin: 0px;
          p {
            margin: 0px;
          }
          .donViPhongBan {
            color: #757575;
          }
        }
      }
      &.border-bottom {
        border-bottom: 1px solid #eaeef4;
      }
    }
  }
  .cpc-form-footer {
    background-color: #fff;
    bottom: 0;
    left: 66px;
    padding: 11px 20px;
    position: fixed;
    right: 0;
    text-align: right;
    &__button {
      background: #1552dc;
      border: 1px solid #1552dc;
      border-radius: 8px;
      .title {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
      }
    }
    .button-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-cancel {
        border-radius: 8px;
        margin-right: 20px;
        height: 43px;
        background-color: #f2f2f2;
        border: 1px solid #f2f2f2;
        .title {
          color: #7589a3;
          font-size: 14px;
          font-weight: 600;
          text-transform: capitalize;
          line-height: 42px;
        }
      }
      .btn-save {
        border-radius: 8px;
        background: #1552dc;
        border: 1px solid #1552dc;
        height: 43px;
        .title {
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          text-transform: capitalize;
          line-height: 42px;
        }
      }
    }
  }
}

.cpc-ranger-picker {
  .bp4-control-group {
    gap: 15px;
    .bp4-input-group {
      border-radius: 8px;
      .bp4-input {
        border: 1px solid $border-color;
        border-radius: 8px;
        box-shadow: none;
        font-size: 14px;
        height: 37px;
        padding: 8px 12px;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.date-filter {
  display: flex;
  gap: 15px;
  .bp4-form-group {
    margin: 0;
  }
}
.arrow-left-icon {
  margin-right: 14px;
}

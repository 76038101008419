.nav-left-item {
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 10px 6px;
  position: relative;
  &-icon {
    font-size: 22px;
    opacity: 0.8;
  }
  &-name {
    color: white;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    line-height: 130%;
    opacity: 0.8;
    text-align: center;
    text-transform: uppercase;
    span {
      color: white;
      display: block;
    }
  }

  .logo {
    height: 36px;
    width: 36px;
  }

  &.active {
    background-color: #0033a5;
    .nav-left-item-icon,
    .nav-left-item-name {
      opacity: 1;
    }
  }
  &:hover {
    background-color: #0033a5;
    color: #fff;
    .nav-left-item-icon,
    .nav-left-item-name {
      opacity: 1;
    }
  }
  &.no-hover {
    height: 66px;
  }
  &.no-hover:hover {
    background-color: var(--blue);
    .nav-left-item-icon,
    .nav-left-item-name {
      opacity: 1;
    }
  }
  .counter {
    background-color: #C22649;
    border-radius: 50%;
    color: #fff;
    font-size: 8px;
    height: 15px;
    line-height: 15px;
    min-width: 15px;
    position: absolute;
    right: 14px;
    text-align: center;
    top: 6px;
  }
  &__child {
    background-color: #fff;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
    left: 100%;
    min-width: 180px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    visibility: hidden;
    z-index: -1;
  }
  &:hover {
    .nav-left-item__child {
      opacity: 1;
      visibility: visible;
      z-index: 8;
    }
  }
}
.nav-left-child {
  &__item {
    align-items: center;
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    position: relative;
    color: #222;
    > * {
      line-height: 100%;
    }
    &:hover {
      background-color: #12309e;
      > * {
        color: #fff;
      }
    }
    .counter-inline {
      display: inline-block;
      background-color: #C22649;
      border-radius: 50%;
      color: #fff;
      font-size: 8px;
      height: 15px;
      line-height: 15px;
      min-width: 15px;
      width: 15px;
      text-align: center;
    }
  }
}
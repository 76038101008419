.cpc-date {
  background-color: var(--gray4);
  display: flex;
  position: relative;
  z-index: 9;
  .react-datepicker {
    display: flex;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  ::placeholder {
    font-size: 14px;
  }
  .cpc-date-picker {
    background-color: transparent;
    border: none;
    border-radius: 100px;
    height: 42px;
    padding: 11px 40px 11px 15px;
    position: relative;
    z-index: 1;
  }
  .cpc-icon-over {
    position: relative;
    .cpc-icon {
      opacity: 0.5;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .close {
    border-radius: 50%;
    cursor: pointer;
    font-size: 8px;
    height: 14px;
    line-height: 14.5px;
    opacity: 0.8;
    position: absolute;
    right: 18px;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    z-index: 2;
  }
  &.theme {
    &-1 {
      background-color: #f2f2f2;
      border-radius: 4px;
      .cpc-date-picker {
        height: 30px;
        line-height: 30px;
        width: 100%;
      }
    }
    &-2 {
      background-color: #fff;
      border: 1px solid #e4e8ed;
      border-radius: 8px;
      .cpc-date-picker {
        color: #000;
        height: 37px;
        width: 130px;
      }
    }
    &-3 {
      background-color: #fff;
      border: 1px solid #e4e8ed;
      border-radius: 8px;
      .cpc-date-picker {
        color: #000;
        height: 37px;
        width: 205px;
      }
    }
  }
}

.forward-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    &:first-child {
      border-top: 0;
    }
  }
  .forward-item-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.forward-item {
  background-color: #F3F7FF;
  border-radius: 8px;
  padding: 10px 15px 10px 30px;
  position: relative;
  .close {
    background-color: #B6BEC9;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    height: 14px;
    line-height: 14px;
    min-width: 14px;
    text-align: center;
    width: 14px;
  }
  &::before {
    background-color: var(--blue);
    content: '';
    display: block;
    height: calc(100% - 30px);
    left: 15px;
    margin-top: 15px;
    position: absolute;
    top: 0;
    width: 2px;
  }
}
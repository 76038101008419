.table-container {
  .error-content {
    padding: 20px 0;
    .description-note {
      margin-top: 14px;
      color: #8a9da7;
    }
  }
  .pt-table {
    width: 100%;
    border-collapse: collapse;
    .heading-table {
      th {
        background-color: var(--blue);
        color: #fff;
        text-align: left;
        text-transform: uppercase;
      }
    }
    th {
      padding: 7.3px 16px;
      background-color: #fff;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #31434b;
    }
    td {
      padding: 12.5px 16px;
      background-color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #31434b;
      .sended {
        justify-content: flex-start;
      }
      &.expired {
        color: red;
      }
    }
    tr {
      border-bottom: 5px solid #eaeef4;
      span {
        color: #31434b;
      }
      .cpc-icon {
        cursor: pointer;
      }
      .text-custom-cursor {
        cursor: pointer;
        font-weight: 500;
        &:hover {
          color: var(--blue);
        }
      }
      .col-stt {
        width: 60px;
      }
      .col-datetime {
        width: 165px;
      }
      .col-action {
        width: 95px;
      }
    }

    tr td:first-child,
    tr th:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    tr td:last-child,
    tr th:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.cpc-button.cpc-danger {
  background-color: var(--red);
  border: none;
  box-shadow: none;
  &:hover {
    background-color: var(--red1) !important;
    box-shadow: none !important;
    border: none;
  }
  .bp4-button-text {
    color: white;
  }
}

.cpc-button.cpc-info {
  background-color: var(--disabled);
  border: none;
  box-shadow: none !important;
  &:hover {
    background-color: var(--border) !important;
    box-shadow: none !important;
    border: none;
  }
  .bp4-button-text {
    color: var(--text);
    box-shadow: none;
  }
}

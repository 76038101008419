.cpc-button {
  background-color: var(--blue);
  border-radius: 100px !important;
  color: white !important;
  font-size: 13px !important;

  font-style: normal;
  font-weight: 600;
  height: 42px;
  line-height: 20px;

  &:disabled {
    background-color: var(--disabled) !important;
    color: #959595 !important;
  }

  .bp4-button-text {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 13px;
  }

  div {
    margin-right: 5px;
  }

  &__theme1 {
    border: none !important;
    border-radius: 8px !important;
    box-shadow: none !important;
  }
  &__theme2 {
    border: none !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    height: 28px;
    min-height: 28px !important;
    .bp4-button-text {
      font-weight: 500;
    }
  }
  &__theme3 {
    border: none !important;
    border-radius: 20px !important;
    box-shadow: none !important;
    height: 28px;
    min-height: 28px !important;
    .bp4-button-text {
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  &__theme4 {
    background-color: #f2f2f2 !important;
    border: none !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    padding: 8px 16px !important;
    width: 96px;
    min-width: auto !important;
    height: 37px;
    white-space: nowrap;
    &:hover {
      box-shadow: none;
    }
    span {
      color: #7589a3 !important;
      font-size: 14px;
      line-height: 15px;
    }
  }
  &__theme5 {
    background-color: #f2f2f2 !important;
    border: none !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    padding: 8px 16px !important;
    min-width: auto !important;
    height: 37px;
    white-space: nowrap;
    &:hover {
      box-shadow: none;
    }
    span {
      color: #7589a3 !important;
      font-size: 14px;
      line-height: 15px;
    }
  }
  &.full-width {
    width: 100%;
  }
  &.red-bg {
    background-color: rgba(232, 8, 8, 0.06) !important;
    .bp4-button-text {
      color: #e80808 !important;
    }
    &:hover {
      background-color: rgba(232, 8, 8, 0.1) !important;
    }
  }
  &.blue {
    background-color: var(--blue) !important;
    .bp4-button-text {
      color: #fff !important;
    }
  }
  &.green {
    background-color: #00aa48 !important;
    .bp4-button-text {
      color: #fff !important;
    }
  }
  &.red {
    background-color: #e80808 !important;
    .bp4-button-text {
      color: #fff !important;
    }
  }
  &.large {
    height: 43px;
    .bp4-button-text {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

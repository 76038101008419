.cpc-dropdown-select {
  border: 1px solid transparent;
  border: 1px solid #e4e8ed;
  border-radius: 8px;
  color: #7589a3;
  display: flex !important;
  height: 37px;
  position: relative !important;
  ::placeholder {
    font-size: 14px;
  }
  &-wrapper {
    .bp4-popover2-target {
      display: block;
    }
    .bp4-popover2 {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.03), 0 8px 24px rgba(0, 0, 0, 0.03);
      .bp4-popover2-content {
        border-radius: 6px!important;
      }
    }
    .not-data {
      color: #7589a3;
      font-size: 13px;
      padding: 10px;
    }
  }
  &-search {
    border-bottom: 0.0625rem solid rgb(233, 236, 239);
    input {
      border: none!important;
      box-shadow: none!important;
      height: 35px;
      line-height: 35px;
      outline: none!important;
      padding-right: 30px;
      &:focus {
        box-shadow: none;
      }
    }
    .bp4-input-action {
      line-height: 100%!important;
      margin-top: -10px!important;
      right: 5px!important;
      top: 50%!important;
      .close {
        color: #616161;
        cursor: pointer;
        display: block;
        font-size: 10px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        width: 20px;
      }
    }
    ::placeholder {
      font-size: 14px;
    }
  }
  .bp4-input {
    background: transparent;
    box-shadow: none !important;
    height: 37px;
    line-height: 37px;
    padding: 0 15px;
    padding-right: 0 !important;
  }
  .bp4-input-action {
    display: block;
    min-width: 40px;
    position: relative !important;
    text-align: right;
    width: 40px;
    .arrow {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      gap: 5px;
      padding-right: 10px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0%, -50%);
      &.open {
        transform: translate(-50%, -50%) rotate(180deg);
      }
      .close-action {
        width: 14px;
        height: 14px;
        line-height: 15px;
        text-align: center;
      }
    }
  }
  &__list {
    li {
      cursor: pointer;
      padding: 10px;
      &:hover,
      &.selected {
        background-color: #eef3ff;
      }
    }
  }
  &.theme {
    &-1 {
      box-sizing: border-box;
      height: unset;
      .bp4-input {
        cursor: pointer;
        height: 30px;
      }
    }
    &-2 {
      background: #f2f2f2;
      border: none;
      border-radius: 4px;
      box-sizing: border-box;
      height: unset;
      .bp4-input {
        cursor: pointer;
        height: 30px;
      }
    }
  }
}

.cpc-department-section,
.cpc-department-contact-list,
.radio-group-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cpc-department-section {
  // min-height: 200px;
  .cpc-department-section {
    min-height: 0;
  }
}

.group-col-content {
  display: table;
  width: 100%;

  [class^='cpc-cols-'],
  [class*=' cpc-cols-'] {
    display: table-cell;
    vertical-align: top;
  }
  .cpc-cols-50 {
    width: 50%;
  }
}

.cpc-save-document-form {
  .label-content {
    color: #000;
    display: block;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 8px;
  }
  .pt-fill {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    font-size: 13px;
    height: 40px;
    line-height: 1.4;
    margin-top: 0;
    padding: 5px 14px;
    width: 100%;
  }
  .pt-textarea {
    height: 70px;
    width: 100% !important;
  }
  .cpc-form-group {
    margin-bottom: 18px;
    margin-top: 12px;
  }
  .btn-save {
    background-image: none !important;
    background-color: #195fff !important;
    border-color: darken(#195fff, 20%) !important;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 600;
    height: 40px;
    min-width: 150px;
    text-align: center;
    .right-icon {
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
    }
    &:hover {
      background-color: darken(#195fff, 20%) !important;
    }
  }
}
.cpc-save-document {
  .title-heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    margin: 0;
    padding: 0;

    .left-icon {
      display: inline-block;
      margin-right: 10px;
      &:before {
        color: #195fff;
      }
    }

    .cpc-close {
      position: absolute;
      top: 50%;
      right: 5px;
      border: 0;
      background: transparent;
      cursor: pointer;
      margin-top: -14px;
      .pt-icon {
        font-size: 25px;
        &::before {
          color: #000;
        }
      }
    }
  }

  .heading-content {
    border-bottom: 1px solid #cecece;
    padding: 20px 15px;
    position: relative;
  }
  .cpc-department-section {
    background-color: #fafafa;
    border-bottom: 1px solid #cecece;
    .pt-checkbox {
      margin-bottom: 0;
    }
    .cpc-unit-info {
      margin: 0;
    }
    .cpc-department-item {
      border-bottom: 0 none transparent;
      font-size: 13px;
      padding-right: 0;
    }
  }
  .file-show {
    .icon-file {
      height: auto;
      max-width: 100%;
    }
    .image-content {
      display: inline-block;
      float: left;
      margin-right: 8px;
      max-width: 25px;
      img {
        height: 32px;
      }
    }
    .file-info {
      font-size: 13px;
      line-height: 1.4;
      .text,
      .note {
        display: block;
      }
      .text {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .note {
        color: #777;
      }
    }
    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }
  .file-show,
  .pt-radio-content {
    padding-top: 6px;
  }
  .main-form-content {
    padding: 16px;
  }
}

.save-document-form-container {
  display: flex;
  .cpc-save-document-form {
    .main-form-content {
      width: 310px;
      background: #fff;
      .file-info .text {
        height: 40px;
      }
    }
    .cpc-department-section {
      max-height: calc(100vh - (116px + 384px));
      overflow-y: auto;
      min-height: 100px !important;
    }
  }
  .main-form-menu {
    width: 190px;
    min-height: 300px;
    border-right: 1px solid #eaeef4;
    li {
      padding: 12px 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      line-height: 21px;
      color: #7589a3;
      font-weight: 400;
      cursor: pointer;
      span {
        margin-left: 11px;
        color: #7589a3;
        display: inline-block;
        margin-top: 3px;
      }
    }
    li.active {
      color: #1552dc;
      span {
        color: #1552dc;
        font-weight: 600;
      }
      .cpc-icon {
        color: #1552dc !important;
      }
    }
  }
}

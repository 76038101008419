.cpc-chat-section {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .cpc-chat-message-section {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    padding: 0;
    // padding-bottom: 220px;
  }

  .cpc-chat-box-section {
    border-top: 1px solid #ddd;
    background: #E0E0E0;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 30px 0 30px;
  }
}

.emoticon {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  &.emoticon-and-check-delete {
    margin-right: 25px;
  }
}
.emoticon-selection {
  border: 1px solid #d6dbe1;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  span {
    cursor: pointer;
    font-size: 14px;
  }
}

.emoticon-block {
  max-width: 100%;
  padding: 5px 10px;
}

.emoticon-slider {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  .slick-track {
    min-width: 146px;
  }
  &.emoticon-slider--no-nav {
    .slick-list {
      flex: 1;
    }
  }
  .slick-slide {
    padding: 5px 2px;
    > div {
      font-size: 0.00001px;
      line-height: 100%;
    }
  }
  .emoticon-arrow-next,
  .emoticon-arrow-prev {
    color: #333;
    font-size: 10px;
    line-height: 100%;
    padding-bottom: 5px;
    padding-top: 5px;
    &:hover {
      color: #c5c5c5;
    }
  }
  .emoticon-arrow-prev {
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 5px;
  }
  .emoticon-arrow-next {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
  }
  &--item {
    width: 30px;
    &--image {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: block;
      font-size: 28px;
      height: 30px;
      line-height: 30px;
      margin: 0;
      padding: 0;
      position: relative;
      text-align: center;
      transition: 0.2s linear;
      width: 100%;
      img {
        max-width: 100%;
        transform: scale(0.8);
        transition: transform 0.2s linear;
      }
      &:hover {
        img {
          transform: scale(1);
        }
      }
      &.dot::before {
        background-color: #2188d6;
        border-radius: 50%;
        bottom: -5px;
        content: '';
        display: inline-block;
        height: 5px;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0%);
        width: 5px;
      }
    }
  }
}

.tags-emoticon {
  align-items: center;
  background-color: #fff;
  border: 1px solid #d5dbe5;
  border-radius: 40px;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin: 0 -2px;
  padding: 3px 5px;
  &__small {
    li {
      padding: 0 !important;
    }
    .total-number {
      color: var(--text-time);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      padding: 0 3px;
    }
  }
  &.tags-emoticon--number {
    border: none;
    margin: 0 -10px;
    li {
      padding: 0 10px;
    }
  }
  li {
    display: flex;
    flex: 0;
    list-style: none;
    padding: 0 2px;
    line-height: 100%;
  }
  .tag-emoticon {
    align-items: center;
    display: inline-flex;
    &__image {
      background-position: center center;
      background-size: contain;
      display: block;
      height: 22px;
      min-width: 22px;
      width: 22px;
      img {
        max-width: 100%;
      }
    }
    &__count {
      margin-left: 5px;
    }
  }
  .tag-emoticon-small {
    align-items: center;
    display: inline-flex;
    &__image {
      font-size: 20px;
      width: 26px;
      img {
        max-width: 100%;
      }
    }
    // &:hover {
    //   box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
    // }
  }
}

.tags-emoticon-history {
  .title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    padding: 12px 20px;
  }
  &__content {
    padding: 20px;
  }
  .tags-emoticon-history__content {
    padding: 0 20px 20px 20px;

    .user-card .info .name {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
    }
    .div-center {
      position: relative;
      width: auto;
      .all-emoji {
        color: #081c36;
        cursor: pointer;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &.active {
          font-weight: 600;
          &:before {
            background-color: #1151f9;
            bottom: 0;
            content: '';
            display: block;
            height: 4px;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
      }
    }
  }
  .tags-emoticon-history__content__synthesis {
    align-items: flex-end;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    height: 48px;
    > span {
      cursor: pointer;
      display: flex;
      padding-bottom: 10px;
      position: relative;
      white-space: nowrap;
      &.active {
        &:before {
          background-color: #1151f9;
          bottom: -0.5px;
          content: '';
          display: block;
          height: 4px;
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }
    .number {
      color: var(--blue);
    }
    .tags-emoticon-history__content__synthesis__emoticons {
      height: 100%;
      padding-left: 20px;
      ul {
        align-items: center;
        display: flex;
        height: 100%;
        margin: 0;
        margin: 0 -9px;
        padding: 0;
        li {
          align-items: center;
          display: flex;
          height: 100%;
          list-style: none;
          padding: 0 9px;
          position: relative;
          &.active {
            &:before {
              background-color: var(--blue);
              bottom: 0;
              content: '';
              display: block;
              height: 4px;
              left: 10%;
              position: absolute;
              width: 80%;
            }
          }
          .tag-emoticon {
            align-items: center;
            cursor: pointer;
            display: flex;
            &__image {
              font-size: 18px;
              img {
                max-width: 100%;
              }
            }
            &__count {
              color: #a9b6c7;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .tags-emoticon-history__content__grouping {
    ul.tags-emoticon-history__content__grouping__box {
      margin: 0;
      padding: 0;
      > li {
        list-style: none;
        margin-top: 20px;
        .tags-emoticon-history__content__grouping__users-emoticons {
          align-items: center;
          display: flex;
          justify-content: space-between;
          ul {
            align-items: center;
            display: flex;
            margin: 0;
            margin: 0 -6px;
            padding: 0;
            li {
              list-style: none;
              padding: 0 6px;
              .tag-emoticon {
                align-items: center;
                display: flex;
                &__image {
                  font-size: 18px;
                  img {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.slick-list,
.slick-slider {
  display: block;
  position: relative;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  user-select: none;
  .slick-list,
  .slick-track {
    transform: translateZ(0);
  }
}

.slick-list {
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
  &:after,
  &:before {
    content: '';
    display: table;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.bp4-control.cpc-checkbox {
  color: var(--gray7);
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin: 0;
  margin-bottom: 0;

  &.bp4-checkbox .bp4-control-indicator {
    border-radius: 4px;
  }

  input:checked ~ .bp4-control-indicator {
    background-color: var(--blue);
    box-shadow: none;
  }
  span {
    font-size: 20px;
    &:focus {
      outline: 0;
    }
  }
  .bp4-control-indicator {
    box-shadow: inset 0 0 0 1px #cfd6e0;
    outline: none !important;
  }
  &.not-label {
    .bp4-control:not(.bp4-align-right) {
      margin-left: 0;
    }
    .bp4-control-indicator {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &.size {
    &-xs {
      span {
        font-size: 16px;
        &:focus {
          outline: 0;
        }
      }
    }
  }
  &.theme {
    &-2 {
      padding: 0;
      .bp4-control-indicator {
        background-color: #bfbfbf;
        border: none;
        border-radius: 10px;
        box-shadow: none;
        height: 16px;
        margin: 0;
        width: 26px;
        transition: 0.3s;
        &::before {
          background: #E8F5E9;
          border-radius: 50%;
          content: "";
          display: block;
          height: 12px;
          left: 2px;
          position: absolute;
          top: 2px;
          width: 12px;
        }
      }
      input:checked ~ .bp4-control-indicator {
        background-color: #4CAF50;
        &::before {
          background-image: none;
          left: auto;
          right: 2px;
        }
      }
    }
  }
}

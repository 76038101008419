.cpc-contact-item {
  border-bottom: 1px solid #ededed;
  padding: 10px 10px 0 50px;
  display: flex;
  gap: 12px;

  .pt-checkbox {
    margin-top: 7px;
  }

  &:last-child {
    border-bottom: 0;
  }

  &:after {
    clear: both;
    content: "";
    display: block;
  }

  .pt-checkbox {
    float: left;
  }

  .cpc-avatar-container {
    float: left;
    position: relative;
    margin-left: 5px;
    margin-right: 13px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-size: cover;
    border: 1px solid #d8d8d8;
    border-radius: 100%;
    background-position: center top;

    &.online {
      .cpc-icon-online {
        span {
          color: #7ed321;
        }
      }
    }

    .cpc-icon-online {
      pointer-events: none;
      position: absolute;
      bottom: -2px;
      right: 0;
      padding: 0;
      margin: 0;

      span {
        font-size: 9px;
        color: #c4c4c4;
      }
    }
  }

  .cpc-avatar {
    opacity: 0;
    width: 30px;
    height: 30px;
    border: 1px solid #d8d8d8;
    border-radius: 100%;
    vertical-align: middle;
  }

  .cpc-contact-name {
    display: flex;
    flex-direction: column;
    flex: 1;
    .cpc-name-container {
      height: 30px;
      span {
        font-size: 13px;
        color: #000000;
        font-weight: 500;
        cursor: pointer;
      }
      .cpc-name-content {
        display: block;
        overflow: hidden;
        margin-bottom: 8px;
        padding-top: 6px;
      }
    }
  }

  .cpc-contact-info {
    font-family: "Roboto";
    margin-bottom: 8px;
    margin-left: 50px;
    cursor: pointer;

    .icon-quote:before {
      font-size: 10px;
    }

    .cpc-text {
      word-wrap: break-word;
      width: calc(100% - 50px);
      img {
        width: 16px;
      }
    }

    div {
      font-size: 13px;
      color: #6f6f6f;
      line-height: 16px;

      span {
        vertical-align: middle;
        margin-right: 12px;

        &::before {
          font-size: 15px;
          color: #6f6f6f;
        }
      }

      .icon-table::before {
        font-size: 12px;
      }
    }
  }

  .pt-control-indicator {
    margin-top: 5px !important;
  }

  .pt-control input:checked ~ .pt-control-indicator {
    background: #195fff;
  }
}

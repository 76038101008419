.brand-wrapper {
  align-items: center;
  color: white;
  display: flex;
  gap: 13px;
  .logo {
    max-height: 80px;
  }
  .brand-name {
    display: flex;
    .env {
      color: white;
      font-weight: 900;
    }
    .cpc {
      color: #ed3237;
      font-style: italic;
      font-weight: 900;
    }
  }
}

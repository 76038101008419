.cpc-textarea {
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: none!important;
  outline: none;
  overflow: hidden;
  user-select: none;
  width: 100%;
  &::placeholder {
    color: #A9B6C7;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
  &:focus, &:active {
    border-color: #1552DC;
  }
  &.theme {
    &-1 {
      border-color: #EAEEF4;
      border-radius: 0;
    }
  }
}
.cpc-file {
  display: flex;
  gap: 7px;
  &__icon {
    height: 30px;
    line-height: 30px;
    object-fit: cover;
    object-position: center center;
    width: 22px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    .name {
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      line-height: 150%;
      word-break: break-word;
      &.nowrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .size {
      color: #959595;
      font-size: 12px;
      font-weight: 400;
      line-height: 130%;
    }
  }
  &.pointer {
    cursor: pointer;
  }
  &:hover {
    background-color: #f3f5f6;
  }
}

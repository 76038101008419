.cpc-popover {
  user-select: none;
  &.bp4-popover2 {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    .bp4-popover2-arrow {
      &:before {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
      }
    }
    .bp4-popover2-arrow-border {
      fill-opacity: 0;
    }
  }
}
:root {
  --text: #222222;
  --text1: #505050;
  --text2: #262626;
  --text-time: #7589a3;
  --blue: #1552dc;
  --disabled: #f2f2f2;
  --hover: #dfe2e7;
  --border: #eaeef4;
  --bg: #eaeef4;
  --gray: #828282;
  --red: #e80808;
  --red1: #ce0404;
  --gray1: #eaeef4;
  --gray2: #959595;
  --gray3: #d9d9d9;
  --gray4: rgba(217, 217, 217, 0.35);
  --gray5: #757575;
  --gray6: #f4f5f6;
  --gray7: #081c36;
  --gray8: #606060;
  --green: #43c300;
  --header: 66px;
  --bg-me: #e7effe;
  --bg-blue-10: #ecf6fa;
  --button-neutral-hover: #dfe2e7;
  --orange: #FF951A;
  --mentionBG: transparent;
  --mentionColor: #2751d9;
}

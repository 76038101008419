.heading {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  min-height: 66px;
  padding: 0 20px;
  > div {
    flex: 0 auto;
  }
  .main-heading-page {
    margin-left: 13px;
    width: 100%;
    span {
      font-size: 18px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.cpc-unit-section {
  .cpc-contact-item {
    padding: 10px 10px 0 60px;
  }
  .cpc-unit-item-title {
    border-bottom: 1px solid #eaeaea;
    padding: 12px 16px 15px;

    &:after {
      clear: both;
      content: "";
      display: block;
    }

    .cpc-unit-info {
      font-size: 13px;
      color: #000000;
      font-weight: 700;
      margin-top: 3px;
      overflow: hidden;
    }

    button {
      float: left;
      border: 0;
      background: transparent;
      padding-left: 0;
      padding-right: 10px;

      .pt-icon {
        cursor: pointer;
        &::before {
          color: #195fff;
        }
      }
    }
  }

  .cpc-department-section {
    .cpc-department-item {
      padding-left: 25px;
    }
  }
}

.avatar-conversation {
  align-items: center;
  display: flex;
  height: 52px;
  min-width: 52px!important;
  width: 52px;
  &__group {
    height: 100%;
    position: relative;
    width: 100%;
    .cpc-avatar {
      border: 1px solid #fff;
    }
    .avatar-0 {
      left: 10px;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .avatar-1 {
      bottom: 0;
      left: 0;
      position: absolute;
      z-index: 3;
    }
    .avatar-2 {
      bottom: 0;
      height: 32px;
      position: absolute;
      right: 0;
      width: 32px;
      z-index: 2;
    }

    .avatar-4-0 {
      left: 0;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .avatar-4-1 {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
    }
    .avatar-4-2 {
      bottom: 0;
      left: 0;
      position: absolute;
      z-index: 2;
    }
    .avatar-4-2-2 {
      align-items: center;
      background-color: var(--bg);
      border: 2px solid #fff;
      border-radius: 50%;
      box-sizing: border-box;
      color: var(--text-time);
      display: flex;
      font-size: 10px;
      font-weight: 700;
      height: 31px;
      justify-content: center;
      line-height: 10px;
      position: absolute;
      position: absolute;
      right: 2px;
      top: 2px;
      width: 31px;
      z-index: 1;
    }
    .avatar-4-3 {
      align-items: center;
      background-color: var(--bg);
      border: 2px solid #fff;
      border-radius: 50%;
      bottom: 0;
      color: var(--text-time);
      display: flex;
      font-size: 10px;
      font-weight: 700;
      height: 28px;
      justify-content: center;
      line-height: 10px;
      position: absolute;
      right: 0;
      width: 28px;
      z-index: 4;
    }
  }
  .cloud-avatar {
    height: 52px;
    min-width: 52px;
    object-fit: contain;
    width: 52px;
    cursor: default;
  }
}

.icon-scss {
  align-items: center;
  background-color: #eaeef4;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  transition: all 0.3s;
  width: 30px;

  &:hover {
    transform: scale(1.2);
  }
  &.auto {
    height: unset;
    width: unset;
  }
  &.sm {
    height: 36px;
    max-height: 36px;
    max-width: 36px;
    min-width: 36px;
    width: 36px;
  }
  &.sm-2 {
    height: 32px;
    max-height: 32px;
    max-width: 32px;
    min-width: 32px;
    width: 32px;
  }
  &.sm-1 {
    height: 30px;
    max-height: 30px;
    max-width: 30px;
    min-width: 30px;
    width: 30px;
  }
  &.xs {
    height: 24px;
    max-height: 24px;
    max-width: 24px;
    min-width: 24px;
    width: 24px;
  }
  &.xs-1 {
    height: 26px;
    max-height: 26px;
    max-width: 26px;
    min-width: 26px;
    width: 26px;
  }
  &.s-1 {
    height: 18px;
    max-height: 18px;
    max-width: 18px;
    min-width: 18px;
    width: 18px;
  }
  &.s {
    height: 16px;
    max-height: 16px;
    max-width: 16px;
    min-width: 16px;
    width: 16px;
  }
  &.square {
    border-radius: 8px;
  }
  &.square-xs {
    border-radius: 4px;
  }
  &.circle {
    border-radius: 50%;
  }
  &.shadow {
    &-1 {
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04);
    }
  }
  &.rotate {
    &-180 {
      &:hover {
        transform: rotate(180deg) scale(1.2);
      }
      transform: rotate(180deg);
    }
  }
  &.theme {
    &-1 {
      border: 1px solid #1552DC;
      color: #1552DC;
    }
    &-2 {
      border: 2px solid #1552DC;
      color: #1552DC;
    }
  }
}

.cpc-tooltip-wrapper {
  line-height: 0;
}
.cpc-tooltip {
  &.theme {
    &-white {
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
      .bp4-popover2-arrow-border,
      .bp4-popover2-arrow-fill {
        fill: #fff;
      }
      .bp4-popover2-content {
        background-color: #fff;
        color: #505050;
        font-size: 13px;
        white-space: nowrap;
      }
    }
  }
}

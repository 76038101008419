* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.div-center {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.mt-30 {
  margin-top: 30px;
}

.mr-7 {
  margin-right: 7px;
}

.section {
  background-color: var(--gray1);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.not-select {
  user-select: none;
}

// css placeholder of Draft.js
.DraftEditor-root .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  color: var(--gray2);
}

.bp4-popover2 {
  border-radius: 8px !important;
}
.bp4-popover2 .bp4-popover2-content {
  border-radius: 8px !important;
}

.opacity-04 {
  opacity: 0.4;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.error {
  color: var(--red);
  font-size: 12px;
}

.hide {
  display: none !important;
}

.drag-over {
  background: white;
  border-radius: 4px;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.9;
  padding: 4px;
  position: absolute;
  width: 100%;
  z-index: 9999;

  .wrapper {
    align-items: center;
    border: 2px dashed var(--text-time);

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    > * {
      position: relative;
      z-index: -1;
    }
  }

  &-active {
    .wrapper {
      border: 2px dashed var(--blue);
    }
    background: rgb(237, 243, 255);
    h2 {
      color: #1552dc !important;
    }
  }

  h2 {
    color: var(--text-time);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 4px;
    text-align: center;
  }

  p {
    color: var(--text-secondary, #7589a3);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}

em-emoji-picker {
  box-shadow: none;
}

.reconnecting-status {
  background-color: #ffb23f;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  padding: 10px 20px;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9;
}

.iframe-dialog-wrapper {
  min-height: calc(100vh - 220px);
  position: relative;
  .iframe-dialog {
    border: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

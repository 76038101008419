.cpc-department-section,
.cpc-department-contact-list,
.radio-group-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cpc-department-section {
  // min-height: 200px;
  .cpc-department-section {
    min-height: 0;
  }
}

.group-col-content {
  display: table;
  width: 100%;

  [class^='cpc-cols-'],
  [class*=' cpc-cols-'] {
    display: table-cell;
    vertical-align: top;
  }
  .cpc-cols-50 {
    width: 50%;
  }
}

.cpc-save-document-form {
  .label-content {
    color: #000;
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 11px;
  }
  .pt-fill {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    font-size: 13px;
    height: 40px;
    line-height: 1.4;
    margin-top: 0;
    padding: 5px 14px;
    width: 100%;
  }
  .pt-textarea {
    height: 70px;
    width: 100% !important;
  }
  .cpc-form-group {
    margin-bottom: 18px;
    margin-top: 0;
  }
  .btn-save {
    background-image: none !important;
    background-color: #195fff !important;
    border-color: darken(#195fff, 20%) !important;
    border-radius: 8px;
    font-size: 14px !important;
    font-weight: 600;
    height: 40px;
    min-width: 110px;
    text-align: center;
    span {
      color: #fff !important;
    }
    .right-icon {
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
    }
    &:hover {
      background-color: darken(#195fff, 10%) !important;
    }
  }
}
.cpc-save-document {
  .title-heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    margin: 0;
    padding: 0;

    .left-icon {
      display: inline-block;
      margin-right: 10px;
      &:before {
        color: #195fff;
      }
    }

    .cpc-close {
      position: absolute;
      top: 50%;
      right: 5px;
      border: 0;
      background: transparent;
      cursor: pointer;
      margin-top: -14px;
      .pt-icon {
        font-size: 25px;
        &::before {
          color: #000;
        }
      }
    }
  }

  .heading-content {
    border-bottom: 1px solid #d9d9d9;
    padding: 20px 15px;
    position: relative;
  }
  .cpc-department-section {
    background-color: #fafafa;
    border-bottom: 1px solid #d9d9d9;
    .pt-checkbox {
      margin-bottom: 0;
    }
    .cpc-unit-info {
      margin: 0;
    }
    .cpc-department-item {
      border-bottom: 0 none transparent;
      font-size: 13px;
      padding-right: 0;
    }
  }
  .file-show {
    .icon-file {
      height: auto;
      max-width: 100%;
    }
    .image-content {
      display: inline-block;
      float: left;
      margin-right: 8px;
      max-width: 25px;
    }
    .file-info {
      font-size: 13px;
      line-height: 1.4;
      .text,
      .note {
        display: block;
      }
      .text {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .note {
        color: #777;
      }
    }
    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }
  .file-show,
  .pt-radio-content {
    padding-top: 6px;
  }
  .main-form-content {
    padding: 20px;
  }
}
.application-get {
  .title {
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 11px;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    width: 270px;
    margin-bottom: 20px;
  }
  .app-action {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    .app-action-item {
      cursor: pointer;
      background-color: #1552dc;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 6px 15px;
      gap: 10px;
      .app-action-description {
        display: flex;
        flex-direction: column;
        gap: 2px;
        .text-download {
          font-size: 7px;
          font-weight: 500;
          color: #fff;
        }
        .text-notification {
          font-size: 8px;
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
}

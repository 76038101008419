.cpc-editor {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: relative;
  &__editor {
    min-width: 0;
    width: 100%;
  }
  &__action {
    display: flex;
    padding: 8px 12px;
  }
  &__extend {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
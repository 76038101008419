$popoverV2: 'popover-v2';
.#{$popoverV2} {
  .pt-popover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    .pt-popover-content {
      border-radius: 6px;
    }
    .pt-popover-arrow {
      &:before {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.cpc-input {
  box-shadow: none;
  font-size: 14px;

  font-style: normal;
  font-weight: 400;
  height: 42px;
  line-height: 20px;

  outline: none;

  span {
    left: 3px !important;
    top: 5px !important;
  }

  i {
    font-size: medium;
    left: 10px !important;
    position: absolute;
    top: 14px !important;
    z-index: 10;
  }

  input {
    background-color: var(--gray4);
    border: 1px solid transparent;
    border-radius: 100px;
    box-shadow: none;
    height: 42px;
    outline: none;
    font-size: 14px;
    &:hover {
      box-shadow: none;
      outline: none;
    }
    &:focus {
      border: 1px solid var(--blue);
      box-shadow: none;
    }
    &::placeholder {
      color: #a9b6c7;
    }
  }
  &.full {
    width: 100%;
  }
  &.theme {
    &-1 {
      input {
        background-color: transparent;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        &:hover {
          box-shadow: none;
          outline: none;
        }
        &:focus {
          border: 1px solid var(--blue);
          box-shadow: none;
        }
        &::placeholder {
          color: #b3b3b3;
          font-weight: 300;
        }
      }
    }
    &-2 {
      height: 33px;
      input {
        background-color: transparent;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        height: 33px;
        &:hover {
          box-shadow: none;
          outline: none;
        }
        &:focus {
          border: 1px solid var(--blue);
          box-shadow: none;
        }
        &::placeholder {
          color: #b3b3b3;
          font-weight: 300;
        }
      }
      i {
        top: 10px !important;
      }
    }
    &-3 {
      height: 36px;
      input {
        background-color: transparent;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        height: 36px;
        &:hover {
          box-shadow: none;
          outline: none;
        }
        &:focus {
          border: 1px solid var(--blue);
          box-shadow: none;
        }
        &::placeholder {
          color: #b3b3b3;
          font-weight: 300;
        }
      }
      i {
        top: 12px !important;
      }
    }
    &-4 {
      height: 37px;
      input {
        background-color: #fff;
        border: 1px solid #e4e8ed;
        border-radius: 8px;
        height: 37px;
        &:focus {
          border: 1px solid var(--blue);
          box-shadow: none;
        }
      }
      i {
        top: 12px !important;
      }
      &.error {
        input {
          border: 1px solid red;
        }
      }
    }
    &-5 {
      height: 37px;
      input {
        background-color: #fff;
        border: 1px solid #e4e8ed;
        border-radius: 8px;
        height: 37px;
        font-size: 14px;
        &:focus {
          border: 1px solid var(--blue);
          box-shadow: none;
        }
      }
      i {
        top: 12px !important;
      }
      &.error {
        input {
          border: 1px solid red;
        }
      }
    }
  }
}

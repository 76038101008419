.cpc-search-message-item {
  position: relative;
  overflow: hidden;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #dfe6ea;
  box-shadow: 0 2px 3px 0 rgba(199, 199, 199, 0.35);
  border-radius: 4px;
  margin-top: 16px;
  .cpc-sms-email {
    float: right;
    margin-top: 5px;

    .icon-mail {
      margin-left: 8px;
    }
  }
  .cpc-right-section {
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: right;
    .cpc-time-delivery {
      margin-bottom: 5px;
      .cpc-time {
        font-family: 'Roboto';
        font-size: 13px;
        color: #6f6f6f;
        margin-left: 6px;
        margin-top: 1px;
        vertical-align: middle;
      }
      .cpc-delivery {
        .cpc-icon {
          vertical-align: middle;
          &::before {
            color: #195fff;
            font-weight: 700;
            font-size: 13px;
          }
        }
      }
    }
    .cpc-sms-email {
      .cpc-sms {
        font-size: 10px;
        font-family: 'Roboto';
        color: #454a52;
      }
      .cpc-icon {
        font-size: 11px;
        vertical-align: middle;
        margin-left: 8px;
        &::before {
          color: #454a52;
        }
      }
    }
  }
  .cpc-item-header {
    width: 85%;
    border-bottom: 1px solid #f2f4f7;
    overflow: hidden;
    position: relative;
    .important-message {
      position: absolute;
      left: 0;
      top: 4px;
    }

    .icon-star,
    .icon-star-no-fill {
      cursor: pointer;
      font-size: 18px;
      float: left;
      margin-right: 5px;
    }
    .icon-star-no-fill::before {
      color: #ababab;
    }
    .cpc-sender {
      cursor: pointer;
      float: left;
      margin-right: 50px;
      h3 {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 13px;
        color: #000000;
        margin-bottom: 7px;
        margin-right: 5px;
        display: inline-block;
      }
      .cpc-department-name {
        opacity: 0.8;
        font-family: 'Roboto';
        font-size: 12px;
        color: #777777;
        margin-bottom: 6px;
      }
    }
    .cpc-receivers {
      float: left;
      & > span {
        font-family: 'Roboto';
        font-size: 12px;
        color: #000000;
        margin-right: 8px;
      }
      .cpc-actions {
        padding-top: 3px;
        display: inline-block;
        &.cpc-icon {
          cursor: pointer;
          &::before {
            color: #797979;
            font-size: 13px;
          }
        }
      }
      .cpc-receivers-list {
        display: inline-block;
        opacity: 0.8;
        font-family: 'Roboto';
        font-size: 12px;
        color: #000000;
        margin-right: 5px;
        .cpc-name::after {
          content: ', ';
        }
        .cpc-name:last-child::after {
          content: '';
        }
        .cpc-icon {
          display: inline-block;
          margin-top: 2px;
          margin-left: 5px;
          cursor: pointer;
          &::before {
            color: #565656;
            font-size: 12px;
          }
        }
      }
    }
  }
  .cpc-item-body {
    padding-top: 12px;
    font-family: 'Roboto';
    font-size: 14px;
    color: #000000;
    line-height: 1.4;
    word-wrap: break-word;
    clear: both;
    * {
      padding: 0;
      margin: 0;
      line-height: 21px;
    }
  }
  .cpc-message-attachment {
    .cpc-message-attachment-item {
      font-family: 'Roboto';
      font-size: 14px;
      padding-top: 12px;
      .cpc-thumb {
        margin-right: 12px;
        img {
          height: 32px;
          &.cpc-image {
            height: 138px;
          }
          &.cpc-image-v2 {
            max-width: 100%;
            height: auto;
            max-height: 180px;
          }
        }
      }
      .cpc-icon {
        cursor: pointer;
        margin-left: 10px;
      }
      .cpc-filename {
        padding-top: 2px;
        font-weight: 500;
        a {
          color: #000000;
        }
      }
      .cpc-filesize {
        font-size: 12px;
        a {
          color: #777777;
        }
      }
    }
  }
}

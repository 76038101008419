$mobile-width: 481px;
$tablet-width: 769px;
$desktop-width: 1024px;

@mixin devices($breakpoint) {
  //the name of the mixin is devices

  @if $breakpoint == tablet {
    @media only screen and (max-width: $tablet-width) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: $mobile-width) {
      @content;
    }
  }
}

@mixin ellipse($line) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin ellipseOld {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

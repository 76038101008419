.cpc-file-thumbnail {
  background: #FFF;
  border-radius: 4px;
  position: relative;
  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
  }
  &.theme {
    &-1 {
      border: 1px solid #EAEEF4;
      .cpc-file {
        box-sizing: border-box;
        justify-content: center;
        padding: 6px 16px;
        .cpc-file__icon {
          height: unset;
          line-height: 0;
          width: unset;
          img {
            height: 68px;
            margin: 0 auto;
          }
        }
        .cpc-file__info {
          display: none;
        }
      }
    }
  }
}
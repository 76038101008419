.name-conversation {
  flex: 1 1;
  min-width: 0;
  .text-name {
    font-size: 14px;
    font-weight: 500;
    line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.share-editor {
  border: 1px solid #E9E9E9;
  .current-content {
    background-color: #F3F7FF;
    border-bottom: 1px solid #E9E9E9;
    padding: 10px;
    .files {
      > div {
        width: 86px;
      }
    }
  }
}

.share-choose {
  border: 1px solid #D7DBE1;
  border-radius: 4px;
  padding: 12px 5px 12px 15px;
  .count {
    background-color: #F1F7FE;
    border-radius: 4px;
    color: #1552DC;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin-left: 4px;
    padding: 4px 7.5px;
    &.beyond {
      background-color: #fff2f2;
      color: #ce0404;
    }
  }
  .user-choose-remove {
    border: 1px solid #000;
    border-radius: 50%;
    font-size: 9px;
    height: 17px;
    line-height: 15px;
    width: 17px;
  }
}

.panel-organization {
  position: relative;
  .panel-forward {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: -1;
    &.display {
      background-color: #fff;
      padding: 10px 20px;
      visibility: visible;
      z-index: 9;
    }
    &__over {
      border: 1px solid #E4E8ED;
      border-radius: 8px;
      padding: 15px;
    }
  }
}

.editor-survey {
  .chat-input__top {
    position: absolute;
    right: 0;
    width: calc(100% - 205px);
    top: 5px;
    .chat-input-top {
      display: flex;
    }
  }
}
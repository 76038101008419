$user-card: 'user-card';

.#{$user-card} {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  .avatar {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
  }
  .info {
    flex: 1;
    font-family: 'Roboto', sans-serif;
    padding-left: 12px;
    .name {
      color: #333;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      margin: 0px;
    }
    .department {
      color: #aaaaaa;
      font-size: 10px;
      margin: 0px;
      margin-top: 2px;
      text-transform: uppercase;
    }
  }
}
.bp4-form-group {
  .bp4-label {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  }
}

.form-group-custom {
  .bp4-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .bp4-text-muted {
    color: red;
  }
  .bp4-form-content {
    .cpc-input {
      input {
        font-size: 14px;
      }
    }
  }
}

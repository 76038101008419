.icon-class {
  &__close {
    background-color: #B6BEC9;
    border-radius: 50%;
    color: #fff;
    display: block;
    font-size: 6px;
    height: 14px;
    line-height: 14px;
    min-width: 14px;
    text-align: center;
    width: 14px;
  }
  &__back {
    color: #b3b3b3;
    font-size: 12px;
  }
  &__dot {
    background-color: #fff !important;
    border: 1px solid #e6e6e6;
    border-radius: 100%;
    color: #1552dc !important;
    cursor: pointer;
    display: inline-block;
    height: 19px;
    line-height: 18px;
    text-align: center;
    width: 18px;
  }
}

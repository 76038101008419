.btn-search-toggle {
  background: 0 none transparent;
  border: 0 none transparent;
  box-shadow: none;
  color: #fff;
  float: right;
  font-size: 16px;
  margin-right: 10px;
  margin-top: 6px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  .icon-search:before {
    color: #fff;
  }
}

.cpc-user-avatar-container {
  position: relative;
  background-size: cover;
  width: 32px;
  height: 32px;
  border: 1px solid #d8d8d8;
  border-radius: 100%;
  background-position: center top;
  margin: 0px 20px;
  display: inline-block;
  vertical-align: middle;
}

.button-link-eoffice {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  border: 1px solid rgba(#fff, 0.5);
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  height: 36px;
  line-height: 1;
  padding: 7px;
  text-align: center;
  .text {
    display: inline-block;
    font-size: 13px;
    line-height: 1.4;
    margin-top: 1px;
    padding-left: 10px;
    vertical-align: top;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.14);
  }
}
.cpc-navbar {
  top: 0;
  left: 0;
  right: 0;
  background-color: #195fff !important;
  padding: 0px 15px !important;
  height: 50px !important;
  z-index: 10;

  display: flex;

  .cpc-logo-full {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;

    a {
      color: #fff;
      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none !important;
      }
    }

    .cpc-logo {
      background-image: url(./logo.svg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0 0;
      display: inline-block;
      height: 34px;
      margin-right: 9px;
      overflow: hidden;
      text-indent: -1983px;
      vertical-align: top;
      width: 160px;
    }

    .cpc-text-muted {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
    }
  }

  .cpc-eofflice {
    &::before {
      color: #fff !important;
    }
  }

  .cpc-user-info {
    font-size: 14px;
    color: #fff;
    position: relative;
    cursor: pointer;
    .use-name {
      font-family: "Roboto";
      font-weight: 700;
    }

    .cpc-user-avatar-container {
      position: relative;
      background-size: cover;
      width: 32px;
      height: 32px;
      border: 1px solid #d8d8d8;
      border-radius: 100%;
      background-position: center top;
      margin: 0px 20px;
      display: inline-block;
      vertical-align: middle;
    }

    .cpc-avatar {
      opacity: 0;
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 15px;
      border-radius: 50%;
    }

    .pt-icon-chevron-down {
      margin-left: 11px;
      color: #cbdbff;
      display: inline-block;
      vertical-align: middle;
    }

    .cpc-menu {
      position: absolute;
      padding-top: 10px;
      display: none;
      top: 100%;
      right: -14px;
      font-family: "Roboto";

      .header-user-info-content {
        list-style: none;
        margin: 0;
        padding: 10px 0;
        width: 218px;
        .text-link {
          color: #000000;
          display: block;
          font-family: "Roboto";
          font-weight: 400;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 1.4;
          padding: 6px 10px;
          text-align: left;
          text-decoration: none;
          white-space: nowrap;
          border-bottom: 1px #f0f3f6 solid;
          .text-content,
          .left-icon {
            display: inline-block;
            vertical-align: middle;
          }
          .left-icon {
            color: #0344d7;
            font-size: 22px;
            padding-right: 5px;
            margin-right: 4px;
            text-align: left;
            width: auto;
            vertical-align: middle;

            .icon-logout {
              font-size: 22px;
            }
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            color: #0344d7;
            text-decoration: none;
          }
          &.usermenu-link {
            color: #000000;
            font-family: "Roboto";
            font-size: 14px;
          }
        }
        .menu-item {
          &:before {
            color: #195fff;
            margin-right: 16px;
            font-size: 18px;
          }
        }
      }
      .header-user-dropdown {
        background-color: #fff;
        border: 1px solid #ededed;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
        border-radius: 4px;
      }
    }

    &:hover {
      .cpc-menu {
        display: block;
      }
    }
  }
  .btn-bg-icon {
    float: right;
    margin-right: 9px;
    margin-top: 6px;
  }
  .button-link-eoffice {
    float: right;
    margin-right: 15px;
    margin-top: 6px;
  }
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.p5 {
  padding: 5px !important;
}
.font-size-25 {
  font-size: 25px !important;
}
.position-relative {
  position: relative;
}
.badge {
  position: absolute;
  width: 19px;
  height: 19px;
  top: 2px;
  right: -8px;
  background-color: #ff001f;
  border-radius: 50%;
  font-size: 11px;
  padding-top: 2px;
  font-weight: bold;
  line-height: 1.3em;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.cpc-chat-messages {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 0 8px 0;
  background-color: #eeeeee;

  .message-new {
    position: absolute;
    bottom: 18px;
    width: 100%;
    text-align: center;

    span {
      background-color: #195fff;
      color: #fff;
      padding: 5px 10px;
      border-radius: 25px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .cpc-chat-messages-scrollbar {
    padding: 0 30px;

    .cpc-chat-date-group {
      .cpc-chat-date {
        position: relative;
        padding: 12px 0;
        margin-top: 5px;
        margin-bottom: 15px;

        .cpc-chat-date-label {
          display: inline-block;
          background:#1c69ff;
          border-radius:100px;
          padding: 4px 17px 3px;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          position: absolute;
          top: 0;
        }
        .cpc-chat-date-line {
          border-top: 1px solid #cccccc;
        }
      }
    }

    .spinner {
      margin: 30px auto 10px auto;
      width: 70px;
      text-align: center;
    }

    .spinner > div {
      width: 18px;
      height: 18px;
      background-color: #ccc;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinner .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .spinner .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
      0%, 80%, 100% { -webkit-transform: scale(0) }
      40% { -webkit-transform: scale(1.0) }
    }

    @keyframes sk-bouncedelay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      } 40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      }
    }

  }
}
.cpc-item-body {
  .emojione {
    width: 25px;
  }
}

@font-face {
  font-display: block;
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/icomoon.eot?x7uzc');
  src: url('fonts/icomoon.eot?x7uzc#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?x7uzc') format('truetype'),
    url('fonts/icomoon.woff?x7uzc') format('woff'),
    url('fonts/icomoon.svg?x7uzc#icomoon') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.icon-all-option:before {
  content: "\e976";
}
.icon-pause:before {
  content: "\e974";
}
.icon-play:before {
  content: "\e975";
}
.icon-windows:before {
  content: "\e973";
}
.icon-copy-v2:before {
  content: "\e972";
}
.icon-quote-fill:before {
  content: "\e971";
}
.icon-doffice:before {
  content: "\e96f";
}
.icon-eoffice-71:before {
  content: "\e970";
}
.icon-department:before {
  content: "\e96e";
}
.icon-seen:before {
  content: "\e96d";
}
.icon-check-dup:before {
  content: "\e96b";
}
.icon-time:before {
  content: "\e96c";
}
.icon-headset:before {
  content: "\e96a";
}
.icon-document:before {
  content: "\e968";
}
.icon-task:before {
  content: "\e969";
}
.icon-gmail:before {
  content: "\e966";
}
.icon-sms:before {
  content: "\e967";
}
.icon-users-conversation:before {
  content: "\e93d";
}
.icon-paint-v2:before {
  content: "\e963";
}
.icon-save-v2:before {
  content: "\e964";
}
.icon-star-v2:before {
  content: "\e965";
}
.icon-arrow-right2:before {
  content: "\e962";
}
.icon-coffee:before {
  content: "\e961";
}
.icon-forward-v2:before {
  content: "\e95f";
}
.icon-reply:before {
  content: "\e960";
}
.icon-do-survey:before {
  content: "\e95e";
}
.icon-leave:before {
  content: "\e95d";
}
.icon-font:before {
  content: "\e959";
}
.icon-android:before {
  content: "\e95a";
}
.icon-apple:before {
  content: "\e95b";
}
.icon-application:before {
  content: "\e95c";
}
.icon-user-octagon:before {
  content: "\e958";
}
.icon-filter-v2:before {
  content: "\e956";
}
.icon-reload:before {
  content: "\e957";
}
.icon-configuration:before {
  content: "\e954";
}
.icon-sort-v2:before {
  content: "\e955";
}
.icon-star-fill:before {
  content: "\e953";
}
.icon-bookmark-remove:before {
  content: "\e952";
}
.icon-calendar-v2:before {
  content: "\e950";
}
.icon-sort:before {
  content: "\e951";
}
.icon-zoom-out:before {
  content: "\e94e";
}
.icon-extend:before {
  content: "\e94f";
}
.icon-format:before {
  content: "\e94d";
}
.icon-sign:before {
  content: "\e94c";
}
.icon-logout:before {
  content: "\e948";
}
.icon-notification:before {
  content: "\e949";
}
.icon-notification-disable:before {
  content: "\e94a";
}
.icon-polygon-down:before {
  content: "\e94b";
}
.icon-down-arrow:before {
  content: "\e947";
}
.icon-like:before {
  content: "\e946";
}
.icon-minus-v2:before {
  content: "\e943";
}
.icon-plus-v2:before {
  content: "\e944";
}
.icon-profile-v2:before {
  content: "\e945";
}
.icon-download:before {
  content: "\e942";
}
.icon-object-union:before {
  content: "\e911";
}
.icon-email-v2:before {
  content: "\e941";
}
.icon-quote:before {
  content: "\e940";
}
.icon-building:before {
  content: "\e93e";
}
.icon-suitcase:before {
  content: "\e93f";
}
.icon-forward:before {
  content: "\e93c";
}
.icon-cloud:before {
  content: "\e93b";
}
.icon-key:before {
  content: "\e939";
}
.icon-user-add:before {
  content: "\e93a";
}
.icon-arrow-left-v2:before {
  content: "\e938";
}
.icon-lock-v2:before {
  content: "\e936";
}
.icon-group-v3:before {
  content: "\e937";
}
.icon-group-v2:before {
  content: "\e932";
}
.icon-bookmarked:before {
  content: "\e933";
}
.icon-gallery:before {
  content: "\e934";
}
.icon-camera:before {
  content: "\e935";
}
.icon-recall:before {
  content: "\e931";
  color: #505050;
}
.icon-save:before {
  content: "\e92f";
}
.icon-pin-v2:before {
  content: "\e977";
}
.icon-pin:before {
  content: "\e930";
}
.icon-star:before {
  content: "\e92e";
}
.icon-filter:before {
  content: "\e92c";
}
.icon-statictis:before {
  content: "\e92d";
}
.icon-chat:before {
  content: "\e902";
}
.icon-message:before {
  content: "\e903";
}
.icon-phone-book:before {
  content: "\e904";
}
.icon-setting:before {
  content: "\e905";
}
.icon-survey:before {
  content: "\e906";
}
.icon-gift:before {
  content: "\e90a";
}
.icon-add:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-group:before {
  content: "\e909";
}
.icon-xmark:before {
  content: "\e92b";
}
.icon-calendar:before {
  content: "\e92a";
}
.icon-load:before {
  content: "\e929";
}
.icon-arrow-right:before {
  content: "\e927";
}
.icon-arrow-left:before {
  content: "\e928";
}
.icon-email:before {
  content: "\e926";
}
.icon-share:before {
  content: "\e923";
}
.icon-copy:before {
  content: "\e924";
}
.icon-edit:before {
  content: "\e925";
}
.icon-3-dot:before {
  content: "\e922";
}
.icon-delete:before {
  content: "\e920";
}
.icon-ring:before {
  content: "\e921";
}
.icon-xls .path1:before {
  content: "\e91a";
  color: rgb(0, 170, 72);
}
.icon-xls .path2:before {
  content: "\e91b";
  margin-left: -0.7666015625em;
  color: rgb(255, 255, 255);
}
.icon-xls .path3:before {
  content: "\e91c";
  margin-left: -0.7666015625em;
  color: rgb(255, 255, 255);
  opacity: 0.3020;
}
.icon-doc .path1:before {
  content: "\e91d";
  color: rgb(21, 82, 220);
}
.icon-doc .path2:before {
  content: "\e91e";
  margin-left: -0.7666015625em;
  color: rgb(255, 255, 255);
}
.icon-doc .path3:before {
  content: "\e91f";
  margin-left: -0.7666015625em;
  color: rgb(255, 255, 255);
  opacity: 0.3020;
}
.icon-pdf .path1:before {
  content: "\e917";
  color: rgb(232, 8, 8);
}
.icon-pdf .path2:before {
  content: "\e918";
  margin-left: -0.7666015625em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path3:before {
  content: "\e919";
  margin-left: -0.7666015625em;
  color: rgb(255, 255, 255);
  opacity: 0.3020;
}
.icon-face:before {
  content: "\e915";
}
.icon-uniE916:before {
  content: "\e916";
}
.icon-upload-file:before {
  content: "\e914";
}
.icon-bookmark-solid:before {
  content: "\e913";
}
.icon-add-group1:before {
  content: "\e912";
}
.icon-bookmark:before {
  content: "\e910";
}
.icon-add-group:before {
  content: "\e90f";
}
.icon-status:before {
  content: "\e90e";
}
.icon-phone:before {
  content: "\e90d";
}
.icon-chevron-down:before {
  content: "\e90c";
}
.icon-three-dot:before {
  content: "\e90b";
}
.icon-lock:before {
  content: "\e901";
}
.icon-user:before {
  content: "\e900";
}

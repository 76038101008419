.cpc-navbar {
  position: absolute !important;
}

.cpc-container-fluid {
  display: flex;

  .cpc-contact-container {
    // position: absolute;
    // left: 70px;
    // top: 0;
    // bottom: 0;
    padding-top: 50px;
    background: #ffffff;
    // border-right: 1px solid #f0f3f6;
    // -webkit-box-shadow: 0px 0px 5px #f0f3f6;
    // box-shadow: 0px 0px 5px #f0f3f6;
    overflow: hidden;
    z-index: 5;
    display: flex;
    flex-direction: column;
    .hot-line {
      background: #e0e0e0;
      color: #d00717;
      font-weight: bold;
      padding: 7px 6px 7px 17px;
      min-height: 33px;
      display: flex;
      align-items: center;
      .icon-old-typical-phone {
        margin-right: 6px;
      }
    }
    .cpc-contact-toggle {
      position: absolute;
      right: 8px;
      top: 112px;
      cursor: pointer;
      span {
        font-size: 20px;
        color: #265fe1;
      }
    }
  }

  .cpc-chat-container {
    // position: absolute;
    // top: 0;
    // bottom: 0;
    padding-top: 50px;
    overflow: hidden;
    z-index: 4;
    flex: 1;
  }
}

@media screen and (max-width: 1215px) {
  .hot-line {
    min-height: 48px !important;
  }
}
@media screen and (max-width: 958px) {
  .hot-line {
    min-height: 34px !important;
  }
}
@media screen and (max-width: 866px) {
  .hot-line {
    min-height: 49px !important;
  }
}

.cpc-contact-toggle-on {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 100px;
  cursor: pointer;
  background: #fff;
  width: 32px;
  padding: 5px;
  text-align: center;
  span {
    font-size: 20px;
    color: #265fe1;
  }
}

.cpc-search-toggle-on {
  position: absolute;
  z-index: 5;
  left: -35px;
  top: 45px;
  cursor: pointer;
  background: #fff;
  width: 35px;
  padding: 5px;
  text-align: center;
  box-shadow: -3px 3px 5px 0px #d8d8d8;
  span {
    font-size: 20px;
    color: #265fe1;
  }
}

.cpc-delete-button {
  width: auto;
  position: absolute;
  z-index: 91;
  bottom: 0;
  right: 30px;
  cursor: pointer;
  text-align: center;

  .pt-button.pt-intent-primary {
    background: #195fff;
    margin-left: 10px;
  }
}

.cpc-profile-container {
  position: absolute;
  top: 50px;
  bottom: 0;
  right: 0;
  background: #ffffff;
  border-left: 1px solid #f0f3f6;
  -webkit-box-shadow: 0px 0px 5px #f0f3f6;
  box-shadow: 0px 0px 5px #f0f3f6;
  height: calc(100% - 50px);
  z-index: 5;
}

.cpc-search-messages-container {
  position: absolute;
  top: 50px;
  bottom: 0;
  right: 0;
  background: #ffffff;
  border-left: 1px solid #f0f3f6;
  -webkit-box-shadow: 0px 0px 5px #f0f3f6;
  box-shadow: 0px 0px 5px #f0f3f6;
  height: calc(100% - 50px);
  z-index: 5;
}

.cpc-config-container {
  position: absolute;
  top: 50px;
  bottom: 0;
  right: 0;
  background: #ffffff;
  border-left: 1px solid #f0f3f6;
  -webkit-box-shadow: 0px 0px 5px #f0f3f6;
  box-shadow: 0px 0px 5px #f0f3f6;
  height: calc(100% - 50px);
  z-index: 5;
}

.rc-select-custom--config {
  padding: 5px 0 15px 10px;
  display: flex;
  align-items: center;
  .rc-select-custom__checkbox {
    margin-top: 10px;
  }
  .rc-select-custom__tree {
    width: calc(100% - 30px);
    .rc-tree-select-selection {
      border-radius: 8px;
    }
    .rc-tree-select-selection--single {
      margin-right: 15px;
      height: 38px;
      padding: 5px 0;
    }
    .rc-tree-select-arrow {
      top: 5px;
    }
  }
  .noti-birthday {
    color: #195fff;
    font-size: 20px;
  }
  .rc-select-custom__dot {
    position: absolute;
    right: 0px;
    top: 9px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ff001f;
    cursor: pointer;
  }
}

.checkbox-tree {
  .spinner {
    height: 200px;
  }
}

.department-tree {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  justify-content: center;
  border-top: 1px solid #eee;
  .department-tree__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }
  .department-tree__icon {
    margin: 0 10px;
    color: #1f63ff;
    font-size: 17px;
  }
  .department-tree__name {
    margin-bottom: 0;
  }
  .department-tree__child {
    padding-left: 35px;
  }

  .department-tree__checkbox-form {
    padding: 15px 0 15px 45px;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    &:first-child {
      margin-top: 10px;
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 5px;
      .pt-control-indicator {
        top: calc(50% + 5px);
      }
    }
    .pt-control-indicator {
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .department-tree__checkbox-form--department {
    padding: 0 0 0 30px;
    &:last-child {
      padding-bottom: 0;
      .pt-control-indicator {
        top: 50%;
      }
    }
    .pt-control-indicator {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.department-tree--no_border_top {
  border-top: none;
  & ~ .department-tree--no_border_top {
    border-top: 1px solid #eee;
  }
}
.w-50px {
  width: 50px;
}
.h-50px {
  height: 50px;
}

.cpc-status {
  text-transform: uppercase;
  font-size: 13px;
  line-height: 15px;
  white-space: nowrap;
  position: relative;
  padding-left: 6px;
  font-weight: 500;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 11px;
    border-radius: 2px;
    background-color: transparent;
    left: 0;
    top: 50%;
    margin-top: -5.5px;
  }
  &.orange {
    color: #ff951a;
    &::before {
      background-color: #ff951a;
    }
  }
  &.blue {
    color: #195fff;
    &::before {
      background-color: #195fff;
    }
  }
  &.green {
    color: #00aa48;
    &::before {
      background-color: #00aa48;
    }
  }
  &.red {
    color: #ff351a;
    &::before {
      background-color: #ff351a;
    }
  }
}

.d-flex {
  display: flex;

  &-wrap {
    flex-wrap: wrap;
  }

  &-column {
    flex-direction: column;
  }

  $aligns: flex-start, center, flex-end;
  @each $align in $aligns {
    &.align-#{$align} {
      align-items: $align;
    }
  }

  $justifys: flex-start, center, flex-end, space-between;
  @each $justify in $justifys {
    &.justify-#{$justify} {
      justify-content: $justify;
    }
  }

  $gaps: 5, 10;
  @each $gap in $gaps {
    $unit: 'px';
    &.gap-#{$gap} {
      gap: #{$gap}#{$unit};
    }
  }
}

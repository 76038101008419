.cpc-image-preview {
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
  &__loading {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__box {
    left: 50%;
    line-height: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__img {
    transition: transform 0.3s ease;
  }
}
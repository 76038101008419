.paginate-list {
  display: flex;
  justify-content: center;
  .pagination {
    margin: 12px 0;
    display: flex;
    gap: 10px;
    .disabled {
      a {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
    .active {
      a {
        background-color: var(--blue);
        color: white;
        &:hover {
          color: #fff;
        }
      }
    }
    li {
      display: inline-block;
      vertical-align: bottom;
      &:last-child {
        a {
          margin-right: 0px;
        }
      }
      a {
        min-width: 32px;
        border: 1px solid #dfe6ea;
        border-radius: 50%;
        aspect-ratio: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;

        color: #738a95;

        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        &:hover {
          color: blue;
        }
      }
      &.break-me {
        vertical-align: bottom;
        button {
          border: none;
          background-color: transparent;
        }
      }
    }
  }
}

.organization-item {
  border-bottom: 1px solid #eaeef4;
  padding: 10px 0;
  .organization-item {
    border-bottom: 1px solid #eaeef4;
    &:last-child {
      border-bottom: 0;
    }
  }
  &:last-child {
    border-bottom: 0;
  }
  .name {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    &.active {
      font-weight: 500;
    }
  }
  .user {
    width: 100%;
    position: relative;
  }
  .user-name {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    gap: 10px;
  }
  .user-phone {
    color: #7589a3;
    font-size: 12px;
    margin: 0;
  }
  .user-status {
    color: #7589a3;
    font-size: 12px;
    margin: 0;
  }
  .user-other-info {
    color: #7589a3;
    font-size: 12px;
    line-height: 120%;
    margin: 0;
  }
  .user-info-icon {
    display: inline-block;
    width: 25px;
  }
  .organization-item-action {
    line-height: 100%;
    transition: 0.3s;
    .cpc-icon {
      color: #7589a3 !important;
      &:hover {
        color: #1552dc !important;
      }
    }
  }
}

.survey-container {
  width: 100%;
  overflow-y: auto;
  border-top: 66px solid white;
  .button-back-fixed {
    width: calc(100% - 80px);
    top: 0;
    right: 0;
    z-index: 10;
  }
  .icon-scss {
    padding: 9px;
    width: 36px;
    height: 36px;
  }
}

.cpc-select.bp4-html-select {
  min-width: 188px;
  height: 42px;
  select {
    height: 42px;
    border-radius: 100px;
    background-color: white;
    width: 100%;
  }
}

.text-lg {
  font-size: 38px;
  font-weight: 600;
}

.text-h3 {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.text-h4 {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.text-h4-normal {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.text-normal {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.text-small {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.text-small-medium {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.text-small-bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

.text-small-uppercase {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}

.text-disable {
  color: rgba(38, 38, 38, 0.4);
}

.text-10-400 {
  font-size: 10px;
  font-weight: 400;
  line-height: 150%;
}

.text-12-400 {
  font-size: 12px!important;
  font-weight: 400!important;
  line-height: 150%!important;
}

.text-12-500 {
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
}

.text-12-400-uppercase {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
}

.text-13-400 {
  font-size: 13px!important;
  font-weight: 400!important;
  line-height: 150%!important;
}

.text-13-500 {
  font-size: 13px;
  font-weight: 500;
  line-height: 150%;
}

.text-13-600 {
  font-size: 13px;
  font-weight: 500;
  line-height: 150%;
}

.text-13-700 {
  font-size: 13px;
  font-weight: 700;
  line-height: 150%;
}

.text-14-400 {
  font-size: 14px!important;
  font-weight: 400!important;
  line-height: 150%!important;
}

.text-14-600 {
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}

.text-14-700 {
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
}

.text-14-500 {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
}

.text-16-700 {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}

.text-18-600 {
  font-size: 18px;
  font-weight: 600;
  line-height: 160%;
}

.text-18-700 {
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
}

.text-light {
  color: #959595;
}

.text-blue {
  color: var(--blue);
}

.text-error {
  color: var(--red1)
}

.text-uppercase {
  text-transform: uppercase;
}

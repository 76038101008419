.cpc-image {
  border: 1px solid #eaeef4;
  cursor: pointer;
  overflow: hidden;
  img {
    display: none;
  }
  &__img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100%;
    position: relative;
    transition: 0.2s;
    transition-timing-function: ease;
    width: 100%;
    &:hover {
      // background-position: 101% 101%;
      transform: scale(1.2);
    }
  }
  &.size {
    &-sm {
      height: 80px;
      width: 80px;
    }
  }
  &.border {
    &-sm {
      border-radius: 5px;
    }
  }
}

.cpc-department-section {
  .cpc-contact-item {
    padding: 10px 10px 0 30px;
  }
  .cpc-department-section {
    .cpc-department-item {
      padding: 15px 0 0 16px;
    }
  }

  .cpc-department-item {
    border-bottom: 1px solid #ededed;
    padding: 12px 0px 12px 0px;

    .cpc-department-contact-list {
      // padding-left: 10px;
    }

    .cpc-item {
      color: #000000;
      font-size: 13px;
      font-weight: 400;
      font-family: "Roboto";
      // padding-left: 10px;
      display: flex;
      align-items: flex-start;
      align-items: center;
      gap: 12px;
      &.active {
        font-weight: 700;

        .pt-icon {
          &::before {
            color: #195fff;
          }
        }
      }

      .cpc-item-number {
        color: #777;
        font-size: 13px;
        margin-left: 7px;
        font-weight: 700;
      }

      .pt-checkbox {
        float: left;
      }

      .cpc-unit-info {
        float: left;
        cursor: pointer;
        width: 100%;
        white-space: nowrap;
        font-size: 13px;
        line-height: 20px;
      }

      .pt-control input:checked ~ .pt-control-indicator {
        background: #195fff;
      }

      button {
        float: left;
        border: 0;
        background: transparent;
        margin-top: -2px;
        margin-right: 5px;
        cursor: pointer;

        span {
          font-size: 16px;
          color: #195fff;
        }

        .pt-icon {
          cursor: pointer;
          &::before {
            color: #9fa2a5;
          }
        }
      }
    }
  }
}

.bp4-dialog {
  border-radius: 6px;
  min-width: 400px;
  width: auto;
  &.popup-config {
    min-height: 346px;
    min-width: 500px;
    .bp4-dialog-header {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    .bp4-dialog-body {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      padding: 0;
    }
  }
}

.bp4-dialog-header {
  box-shadow: 0 1px 0 #f4f5f6;
  padding: 8px 20px;
  .bp4-heading {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }
  .bp4-dialog-close-button {
    color: #959595;
    .bp4-icon svg:not([fill]) {
      fill: #959595;
    }
  }
}

.bp4-dialog-body {
  background-color: white;
  padding: 20px;
  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
}

.bp4-dialog-footer {
  border-top: 1px solid #f4f5f6;
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;

  .footer-right {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    .cpc-button {
      border-radius: 8px !important;
      padding: 12px 15px;
      .bp4-button-text {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.w-400 {
  width: 400px;
}

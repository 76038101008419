.cpc-scrollbar {
  position: relative;
  &__vthumb {
    background-color: #ccc;
    border-radius: 5px;
    right: -1px;
  }
  &__hthumb {
    background-color: #ccc;
  }
}
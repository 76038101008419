.cpc-user {
  align-items: center;
  display: flex;
  gap: 13px;
  &.pointer {
    cursor: pointer;
  }
  .user-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    &.xs {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
    &.xs-600 {
      font-size: 13px;
      font-weight: 600;
      line-height: 150%;
    }
    &.sm {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }
    &.lg-700 {
      font-size: 18px;
      font-weight: 700;
      line-height: 160%;
    }
  }
  .user-infos {
    color: #7589a3;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

@for $i from 0 through 13 {
  $value: $i * 5;
  $unit: '';
  @if $i != 0 {
    $unit: 'px';
  }

  .p-#{$i*5} {
    padding: #{$value}#{$unit} !important;
  }
  .pt-#{$i*5} {
    padding-top: #{$value}#{$unit} !important;
  }
  .pr-#{$i*5} {
    padding-right: #{$value}#{$unit} !important;
  }
  .pb-#{$i*5} {
    padding-bottom: #{$value}#{$unit} !important;
  }
  .pl-#{$i*5} {
    padding-left: #{$value}#{$unit} !important;
  }
  .pv-#{$i*5} {
    padding-left: #{$value}#{$unit} !important;
    padding-right: #{$value}#{$unit} !important;
  }
  .ph-#{$i*5} {
    padding-bottom: #{$value}#{$unit} !important;
    padding-top: #{$value}#{$unit} !important;
  }
  .m-#{$i*5} {
    margin: #{$value}#{$unit} !important;
  }
  .mt-#{$i*5} {
    margin-top: #{$value}#{$unit} !important;
  }
  .mr-#{$i*5} {
    margin-right: #{$value}#{$unit} !important;
  }
  .mb-#{$i*5} {
    margin-bottom: #{$value}#{$unit} !important;
  }
  .ml-#{$i*5} {
    margin-left: #{$value}#{$unit} !important;
  }
  .mv-#{$i*5} {
    margin-left: #{$value}#{$unit} !important;
    margin-right: #{$value}#{$unit} !important;
  }
  .mh-#{$i*5} {
    margin-bottom: #{$value}#{$unit} !important;
    margin-top: #{$value}#{$unit} !important;
  }
}

@for $i from 1 through 25 {
  $value: $i * 10;
  .min-width-#{$value} {
    min-width: #{$value}px !important;
  }
  .max-width-#{$value} {
    max-width: #{$value}px !important;
  }
}

@for $i from 0 through 20 {
  $value: $i * 10;
  $unit: '';
  @if $i != 0 {
    $unit: 'px';
  }

  .height-#{$value} {
    height: #{$value}#{$unit} !important;
  }
  .width-#{$value} {
    width: #{$value}#{$unit} !important;
  }
}

$text-aligns: left, center, right;
@each $align in $text-aligns {
  .text-#{$align} {
    text-align: $align !important;
  }
}

@for $i from 10 through 40 {
  $unit: '';
  @if $i != 0 {
    $unit: 'px';
  }
  .font-size-#{$i} {
    font-size: #{$i}#{$unit} !important;
  }
}

$borders: top, left, bottom, right;
@each $border in $borders {
  .border-#{$border} {
    border-#{$border}: 1px solid #f4f5f6;
  }
}

$weights: 300, 400, 500, 600, 700;
@each $weight in $weights {
  .font-weight-#{$weight} {
    font-weight: #{$weight};
  }
}

$linesClamp: 1, 2, 3;
@each $lineClamp in $linesClamp {
  .line-clamp-#{$lineClamp} {
    @include ellipse(#{$lineClamp});
  }
}

$cursors: pointer, default, not-allowed;
@each $cursor in $cursors {
  .cursor-#{$cursor} {
    cursor: #{$cursor};
  }
}

$whiteSpaces: 'nowrap', 'pre', 'normal';
@each $whiteSpace in $whiteSpaces {
  .white-space-#{$whiteSpace} {
    white-space: #{$whiteSpace};
  }
}

$wordBreaks: 'break-word', 'normal', 'break-all';
@each $wordBreak in $wordBreaks {
  .word-break-#{$wordBreak} {
    word-break: #{$wordBreak};
  }
}

$rotates: '90', '180';
@each $rotate in $rotates {
  .rotate-#{$rotate} {
    transform: rotate(#{$rotate}deg);
  }
}

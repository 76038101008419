.cpc-search-user-suggestion {
  position: relative;
  input {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    padding: 9px 10px;
    font-family: 'Roboto';
    font-size: 13px;
    margin-top: 13px;
    height: 40px;
  }
  .cpc-user-suggestion {
    position: absolute;
    z-index: 20;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    height: 250px;
    background: #ffffff;
    background-image: linear-gradient(-180deg, #ffffff 0%, rgba(255,255,255,0.00) 100%);
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    padding: 9px 0;
    .cpc-not-found {
      padding: 8px 16px;
      font-family: 'Roboto';
      font-size: 12px;
      color: #777777;
    }
    .cpc-user-suggestion-item {
      padding: 8px 16px;
      cursor: pointer;
      &:hover {
        background: #f0f3f6;
      }

      .cpc-user-suggestion-item-avatar {
        display: inline-block;
        background-size: cover;
        width: 30px;
        height: 30px;
        border: 1px solid #d8d8d8;
        border-radius: 100%;
        background-position: -5px 0px;
        vertical-align: middle;
        margin-right: 10px;
      }

      .cpc-avatar {
        opacity: 0;
        width: 30px;
        height: 30px;
        margin-right: 14px;
      }
      .cpc-user-name {
        display: inline-block;
        font-family: 'Roboto';
        font-size: 13px;
        font-weight: 500;
        color: #000000;
      }
    }
  }
}

.cpc-receivers-list-more {
  .pt-minimal {
    box-shadow: none;
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .pt-popover {
    .pt-popover-arrow {
      display: none;
    }
    .pt-popover-content {
      background: #fff;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
      color: #000000;
      font-family: 'Roboto';
      font-size: 13px;
      line-height:21px;
      padding: 10px 12px;
    }
  }
}

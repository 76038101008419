.cpc-favourite-item {
  border-bottom: 1px solid #f0f3f6;
  padding: 10px 0px 0px 10px;

  .cpc-item {
    color: #000000;
    font-size: 13px;
    font-weight: 500;
    font-family: "Roboto";
    display: flex;
    align-items: flex-start;
    &.active {
      font-weight: 700;

      .pt-icon {
        &::before {
          color: #195fff;
        }
      }
    }
    .cpc-item-number {
      color: #777;
      font-size: 13px;
      margin-left: 7px;
      font-weight: 700;
    }

    .cpc-avatar {
      margin-left: 5px;
      margin-right: 13px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      vertical-align: middle;
    }

    .pt-checkbox {
      float: left;
    }

    .cpc-unit-info {
      float: left;
      cursor: pointer;
      margin-top: -6px;
      width: 70%;
      .cpc-name,
      .cpc-item-number {
        vertical-align: middle;
      }
      .cpc-name-content {
        display: block;
        overflow: hidden;
        margin-top: 6px;
      }
    }

    .pt-control input:checked ~ .pt-control-indicator {
      background: #195fff;
    }

    button {
      float: left;
      border: 0;
      background: transparent;
      margin-top: -2px;
      margin-right: 5px;

      span {
        font-size: 16px;
        color: #195fff;
      }

      .pt-icon {
        cursor: pointer;
        &::before {
          color: #9fa2a5;
        }
      }
    }

    .cpc-menu {
      float: right;

      button {
        padding-left: 0;
        padding-right: 0;
      }

      .icon-dot3-menu {
        font-size: 18px;
        &::before {
          color: #736e6e;
        }
      }
    }
  }
}

.cpc-modify-favourite-dialog {
  padding-bottom: 15px !important;

  .cpc-list-container {
    padding: 20px 20px 10px 20px;

    .cpc-item {
      margin-bottom: 23px;
      display: flex;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }

      .cpc-avatar-container {
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 13px;
        background-size: cover;
        width: 35px;
        height: 35px;
        border: 1px solid #d8d8d8;
        border-radius: 100%;
        background-position: -5px 0px;
        cursor: pointer;

        &.online {
          .cpc-icon-online {
            span {
              color: #7ed321;
            }
          }
        }

        .cpc-icon-online {
          pointer-events: none;
          position: absolute;
          bottom: -2px;
          right: 0;
          padding: 0;
          margin: 0;

          span {
            font-size: 9px;
            color: #c4c4c4;
          }
        }
      }

      .cpc-avatar {
        width: 35px;
        height: 35px;
        opacity: 0;
        vertical-align: middle;
      }

      .cpc-contact-name {
        span {
          font-size: 13px;
          color: #000000;
          font-weight: 500;
          cursor: pointer;
        }
      }

      .cpc-text {
        font-size: 13px;
        font-family: "Roboto";
        font-weight: 500;
        color: #000000;
        display: inline-block;
        margin-top: 8px;
      }

      button {
        float: right;
        background: transparent;
        border: 0;
        margin-top: 10px;
        margin-right: 5px;
        cursor: pointer;

        .pt-icon {
          &::before {
            color: #8f8e94;
          }
        }
      }
    }
  }
}
.cpc-fav-name {
  flex-grow: 1;
}

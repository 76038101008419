.cpc-logo-content {
  background: url(logo-with-text.png) no-repeat 0 0;
  background-size: cover;
  height: 70px;
  overflow: hidden;
  text-indent: -1985px;
  width: 260px;
}
.download-container {
  background: url(./bg-download-page.jpg) no-repeat 0 0;
  background-size: cover;
  height: 100%;
  bottom: 0;
  left: 0;
  min-height: 460px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  text-align: center;

  .btn-download-app {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 0 none transparent;
    display: inline-block;
    font-size: 0;
    height: 45px;
    margin: 0 5px;
    overflow: hidden;
    text-indent: -1985px;
    width: 150px;

    &.btn-dl-app-store {
      background-image: url(./btn-dl-app-store.svg);
    }
    &.btn-dl-google-play {
      background-image: url(./btn-dl-google-play.svg);
    }
    &.btn-dl-app-store-beta {
      background-image: url(./btn-dl-app-store-beta.svg);
    }
    &.btn-dl-google-play-beta {
      background-image: url(./btn-dl-google-play-beta.svg);
    }
  }
  .btn-group {
    margin-left: -5px;
    margin-right: -5px;
    padding-top: 15px;
  }
  .cpc-logo-content {
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
  }
  .description {
    color: #212121;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.1;
    text-align: center;
  }
  .cpc-download-page {
    max-width: 330px;
    width: 100%;
    overflow: hidden;
    padding: 0 5px 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: auto;
    .title {
      color: #2905A1;
      font-size: 38px;
      margin: 10px 0 30px 0;
      font-stretch: expanded;
    }
  }
  .cpc-footer {
    bottom: 0;
    color: #989899;
    font-size: 11px;
    left: 0;
    line-height: 1.4;
    padding: 10px 20px 16px;
    position: absolute;
    text-align: center;
    width: 100%;
  }
}

$font-family: 'Roboto'; // 300, 400, 500, 700
$blue: #105aff;
$while: #fff;
$filter-bg: #f8f9f9;
$table-row: #f8f9f9;
$table-border: #e2e2e2;
.data-table {
  font-family: $font-family;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  .table-min-height {
    min-height: 430px;
  }
  table {
    border-left: 1px solid $table-border;
    border-right: 1px solid $table-border;
    border-spacing: 0;
    width: 100%;
    &.middle {
      thead tr th,
      tbody tr td {
        vertical-align: middle;
      }
    }
    thead tr th,
    tbody tr td {
      min-width: 50px;
      padding: 6px 6px;
      vertical-align: top;
    }
    thead tr.thead th {
      background: $blue;
      border: none;
      border-spacing: 0px;
      color: $while;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: left;
      .table-filter-sort-colum {
        align-items: center;
        display: inline-flex;
        .text-sort {
          font-size: 12px;
          font-weight: 500;
          line-height: 1.4rem;
          text-transform: uppercase;
          white-space: nowrap;
        }
        .icon {
          font-size: 5px;
          line-height: 1.4rem;
          margin-left: 5px;
        }
      }
    }
    thead tr.tfilter th {
      background: $filter-bg;
      border-bottom: 1px solid $table-border;
      .filter-column-control {
        border: 1px solid #dfe1e4;
        border-radius: 3px;
        position: relative;
        input,
        select {
          border: none;
          box-shadow: none;
          color: #333333;
          font-weight: 400;
          height: 36px;
          line-height: 36px;
          margin: 0px;
          padding: 0 10px;
          width: 100%;
        }
        &.filter-column-control-date {
          input {
            background-image: url('table-filter-date.svg');
            background-position: calc(100% - 10px) center;
            background-repeat: no-repeat;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          background: $while;
          border-bottom: 1px solid $table-border;
          padding-bottom: 8px;
          padding-top: 8px;
        }
        &:nth-child(2n) td {
          background: $table-row;
        }
      }
      tr.pointer {
        td {
          cursor: pointer;
        }
      }
    }
  }
  .error-content {
    text-align: center;
    .description-note {
      font-size: 14px;
    }
  }
}

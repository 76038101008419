@import '../../../../assets/scss/mixin';

.files-upload {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .item-img {
    box-shadow: 0 0 0 1px #e5e5e5;
    height: 100%;
    max-height: 50px;
    max-width: 100%;
    width: auto;
    &.document {
      padding: 5px;
      width: 50px;
    }
  }
  .file-item {
    max-height: 50px;
    max-width: 100%;
    position: relative;
  }
  .file-loading {
    max-height: 50px;
    max-width: 100%;
    position: relative;
    .div-center {
      height: 100%;
      position: absolute;
      width: 100%;
      .CircularProgressbar {
        height: 30px;
      }
    }

    img {
      height: 50px;
    }
  }
  .cpc-file-size {
    font-size: 10px;
    font-weight: 500;
    padding: 0 10px;
    position: absolute;
  }
  .cpc-file-name {
    background-color: var(--text-primary, #081c36);
    bottom: 0;
    color: white;
    font-size: 10px;
    font-weight: 500;
    left: 0;
    line-height: 1.5;
    opacity: 0.3499999940395355;
    padding: 0 5px;
    position: absolute;
    width: 100%;
    @include ellipseOld;
  }
  .bg-close {
    align-items: center;

    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    &:hover {
      .icon-scss {
        display: flex;
      }
    }
    .icon-scss {
      display: none;

      .cpc-icon {
        cursor: pointer;
      }
    }
  }
}
